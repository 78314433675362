import { CALL_API, Schemas } from '../middleware/api';

/**
 * Fetch all video galleries
 */

export const VIDEO_GALLERIES_REQUEST = 'VIDEO_GALLERIES_REQUEST';
export const VIDEO_GALLERIES_SUCCESS = 'VIDEO_GALLERIES_SUCCESS';
export const VIDEO_GALLERIES_FAILURE = 'VIDEO_GALLERIES_FAILURE';

const fetchVideoGalleries = () => ({
  [CALL_API]: {
    types: [VIDEO_GALLERIES_REQUEST, VIDEO_GALLERIES_SUCCESS, VIDEO_GALLERIES_FAILURE],
    endpoint: 'acf/v3/videogalleries',
    schema: Schemas.VIDEO_GALLERY_ARRAY,
  },
});

export const loadVideoGalleries = () => (dispatch, getState) => {
  const galleries = getState().entities.videoGalleries;
  if (Object.keys(galleries).length > 0) {
    return null;
  }

  return dispatch(fetchVideoGalleries());
};
