import htmlToPdfMake from 'html-to-pdfmake';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import moment from 'moment';
import chunk from 'lodash/chunk';

// Set param
pdfMake.vfs = pdfFonts.pdfMake.vfs;

// PDF images
const headerImageUrl =
  'https://admin.freestylediabetes.co.uk/wp-content/uploads/2020/12/Academy_pdf_certificate_header_UPDATED_V2-copy.jpg';

// Get image data blob from url
async function getImageData(url) {
  return new Promise((resolve, reject) => {
    fetch(url, {
      method: 'GET',
    })
      .then(res => res.blob())
      .then(dataBlob => {
        const reader = new FileReader();

        // Read the data
        reader.readAsDataURL(dataBlob);
        reader.onloadend = () => {
          resolve(reader.result);
        };
      })
      .catch(error => reject(error));
  });
}

// Put the image into pdf columns
function getColumnsFromImages(images) {
  const perRow = 4;
  const margin = {
    margin: [32, 0, 0, 40],
    width: '*',
    text: '',
  };

  // Make the image into per row chunks
  const chunks = chunk(images, perRow);

  return chunks.map(imageChunk => {
    // New columns with starting margin
    const columns = [margin];

    // Put the image into columns
    imageChunk.forEach(image =>
      columns.push({
        alignment: 'center',
        image,
        width: 90,
        margin: [0, 0, 0, 10],
      }),
    );

    // Add the bottom of the column with margin
    columns.push(margin);

    // The final data
    return {
      columns: [
        {
          columns,
        },
      ],
    };
  });
}

// Get module images for certification
async function getModuleImagesForCertification(modules) {
  const getImageTasks = modules
    .map(module => {
      // Use the incomplete cert by default
      let imageUrl = module.incompleteCertificateIcon;

      // Check if the module is completed or not
      if (module.completed) {
        imageUrl = module.certificateIcon;
      }

      if (imageUrl) {
        return getImageData(imageUrl);
      }
      return false;
    })
    .filter(t => t); // Filter out the false

  // Get all the images
  try {
    const imageArray = await Promise.all(getImageTasks);
    return imageArray;
  } catch (error) {
    console.error('there is something wrong with getting module certification images', error);
    return [];
  }
}

async function generateModuleCertificatePDF(modules, userName) {
  // Get the header and fixed images
  let headerImage = null;
  let imageArray = [];

  try {
    headerImage = await getImageData(headerImageUrl);

    // Get all the images
    imageArray = await getModuleImagesForCertification(modules);
  } catch (error) {
    console.error('Download certificate - error while getting images', error);
    alert('Sorry, something went wrong while generating Certificate - Please make sure you have internet connection');
    return false;
  }

  // Get the current date
  const date1 = moment().format('MMMM D').toUpperCase();
  const date2 = moment().format('Do').toLocaleLowerCase().replace(/\d+/g, '');
  const date3 = moment().format('YYYY').toUpperCase();

  const htmlContent = '<p>' + date1 + '<sup>' + date2 + '</sup> ' + date3 + '</p>';
  const pdfDateContent = htmlToPdfMake(htmlContent, { defaultStyles: { sup: { fontSize: 8, baseline: -10 } } });

  // Create the pdf definition
  const documentDefinition = {
    pageMargins: [0, 0],
    header: {
      columns: [
        {
          width: 600,
          image: headerImage,
        },
      ],
    },
    content: [
      {
        text: 'This is to certify that',
        style: 'firstHeader',
      },
      {
        text: userName,
        style: 'name',
      },
      {
        text: 'Has completed the FreeStyle Academy',
        style: 'header',
        marginBottom: 10,
      },
      {
        stack: [pdfDateContent],
        width: 'auto',
        style: 'lastHeader',
      },
    ],

    styles: {
      name: {
        fontSize: 30,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'center',
      },
      firstHeader: {
        margin: [0, 200, 0, 0],
        fontSize: 14,
        bold: false,
        alignment: 'center',
      },
      header: {
        fontSize: 14,
        bold: false,
        margin: [0, 10, 0, 0],
        alignment: 'center',
      },
      lastHeader: {
        margin: [0, 0, 0, 20],
        fontSize: 14,
        bold: false,
        alignment: 'center',
      },
      teal: {
        color: '#68ccce',
      },
      smallPrint: {
        color: '#7f7f7f',
        fontSize: 9,
        margin: [0, 40, 0, 40],
        alignment: 'center',
      },
      tableHeader: {
        fillColor: '#4CAF50',
        color: 'white',
      },
    },
    defaultStyle: {
      columnGap: 20,
    },
  };

  // Add on the module content
  documentDefinition.content = documentDefinition.content.concat(getColumnsFromImages(imageArray));

  const copyright =
    '© ' +
    moment().format('YYYY') +
    ' Abbott. The sensor housing, FreeStyle Libre, and related brand marks are marks of Abbott. ADC-99875 v2.0 10/24';

  // Add to the bottom of the content
  documentDefinition.content.push({
    text: copyright,
    style: 'smallPrint',
  });

  // Create the pdf and trigger download
  pdfMake.createPdf(documentDefinition).download('academy-certificate.pdf');
}

async function generateVideoCertificatePDF(userName, contentPdf, headerPdf, footerPdf) {
  // Get the header and fixed images
  let headerImage = null;

  try {
    headerImage = await getImageData(headerPdf);
  } catch (error) {
    console.error('Download certificate - error while getting images', error);
    alert('Sorry, something went wrong while generating Certificate - Please make sure you have internet connection');
    return false;
  }

  // Get the current date
  const date = moment().format('MMMM Do YYYY').toUpperCase();

  // Create the pdf definition
  const documentDefinition = {
    header: {
      columns: [
        {
          width: 600,
          image: headerImage,
        },
      ],
    },
    footer: {
      columns: [
        {
          text: footerPdf,
          style: 'footer',
        },
      ],
    },
    content: [
      {
        text: 'This is to certify that',
        style: 'firstHeader',
      },
      {
        text: userName,
        style: 'name',
      },
      {
        text: contentPdf,
        style: 'header',
      },
      {
        text: date,
        style: 'lastHeader',
      },
    ],

    styles: {
      name: {
        fontSize: 30,
        bold: true,
        margin: [0, 10, 0, 0],
        alignment: 'center',
      },
      firstHeader: {
        margin: [0, 400, 0, 0],
        fontSize: 14,
        bold: false,
        alignment: 'center',
      },
      header: {
        fontSize: 14,
        bold: false,
        margin: [0, 10, 0, 0],
        alignment: 'center',
      },
      lastHeader: {
        margin: [0, 10, 0, 40],
        fontSize: 14,
        bold: false,
        alignment: 'center',
      },
      teal: {
        color: '#68ccce',
      },
      footer: {
        margin: [0, 10, 0, 10],
        color: '#7f7f7f',
        fontSize: 9,
        alignment: 'center',
      },
      tableHeader: {
        fillColor: '#4CAF50',
        color: 'white',
      },
    },
    defaultStyle: {
      columnGap: 20,
    },
  };

  // Create the pdf and trigger download
  pdfMake.createPdf(documentDefinition).download('on-demand-certificate.pdf');
}

export { generateVideoCertificatePDF, generateModuleCertificatePDF };
