import React, { Component } from 'react';
import ImageGallery from 'react-image-gallery';

// Import the Image gallery css
import "react-image-gallery/styles/css/image-gallery.css";

class MeetingBox extends Component {
  transformBoxData(data) {
    // Figure out the slides
    const slides = [];
    if (data.acf && data.acf.slides) {
      data.acf.slides.forEach((slide) => {
        slides.push({
          original: slide.image,
          description: slide.description,
          thumbnail: slide.image,
        })
      })
    }

    return {
      title: data.title.rendered,
      content: data.content.rendered,
      slides,
    }
  }

  render() {
    const {
      data,
      onClose,
    } = this.props;

    // Transform the data first
    const boxData = this.transformBoxData(data);

    let content = null;
    if (boxData.slides.length > 0) {
      content = <ImageGallery
        items={boxData.slides}
        showIndex
        showNav
      />
    }

    return (
      <div className="row meeting-box">
          <div className="col-12">
            <button className="btn btn-orange back-button" onClick={onClose}>Back</button>
            <h3 className="title">{boxData.title}</h3>
            {content}
          </div>
      </div>
    )
  }
}

export default MeetingBox;
