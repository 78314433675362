import React from 'react';
import PropTypes from 'prop-types';

const Loader = ({ text }) => {
  return (
    <div className="loader">
      <div className="loader-spinner"></div>
      {text && <h1 className="loader-text">{text}</h1>}
    </div>
  );
};

// Props
Loader.propTypes = {
  text: PropTypes.string,
};
Loader.defaultProps = {
  text: '',
};

export default Loader;
