import React, { Component } from 'react';

import CircularProgressbar from 'react-circular-progressbar';
import reactStringReplace from 'react-string-replace';
import 'react-circular-progressbar/dist/styles.css';

export default class ModuleIntro extends Component {
  CustomContentProgressbar(props) {
    // Handle there is no progress percentage
    let percentageText = !isNaN(this.props.progress) ? `${this.props.progress.toFixed()}%` : '0%';

    return (
      <div className="progress-bar-container">
        <div style={{ position: 'absolute' }}>
          <CircularProgressbar
            percentage={this.props.progress}
            counterClockwise
            strokeWidth={2}
            styles={{ path: { stroke: this.props.moduleColour }, trail: { stroke: this.props.moduleColour, opacity: 0.5 } }}
          />
        </div>
        <div style={{ color: this.props.moduleColour }} className="progress-bar-content">
          <div style={{ color: this.props.moduleColour }} height={180}>
            <img src={this.props.moduleIcon} alt="Module Icon" />
            <h4 dangerouslySetInnerHTML={{ __html: this.props.postTitle }}></h4>
            {percentageText}
          </div>
        </div>
      </div>
    );
  }
  render() {
    // eslint-disable-next-line jsx-a11y/iframe-has-title
    reactStringReplace(this.props.moduleIntro.intro, <iframe>(.*?)</iframe>, (match, i) => <div class="video-container">{match}</div>);

    // eslint-disable-next-line jsx-a11y/iframe-has-title
    reactStringReplace(this.props.moduleIntro.subintro, <iframe>(.*?)</iframe>, (match, i) => <div class="video-container">{match}</div>);
    console.log(this.props.moduleIntro);
    const { intro, subintro } = this.props.moduleIntro;

    return (
      <div className="row module-intro-area">
        <div className="col-12 col-md-4 col-lg-3">
          <div className="progress-area">{this.CustomContentProgressbar()}</div>
        </div>
        <div className="col-12 col-md-8 col-lg-9">
          <div className="module-intro-container">
            <div className="module-intro-position">
              <div className="module-intro" dangerouslySetInnerHTML={{ __html: intro }}></div>
              <div dangerouslySetInnerHTML={{ __html: subintro }}></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
