import React, { Component } from 'react';
import PropTypes from 'prop-types';

class GDPR extends Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      checked: false,
      success: false,
      error: false,
      loading: false,
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleSubmit() {
    const { onConfirmRequest, data } = this.props;

    // Set loading
    this.setState({
      loading: true,
    });

    // Do the request
    onConfirmRequest(data)
      .then(data => {
        // Check for error
        if (data.error) {
          this.setState({
            error: data.error,
            loading: false,
          });
        } else {
          this.setState({
            success: true,
            loading: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          error,
          loading: false,
        });
      });
  }

  render() {
    // Looks like the client want to go back to normal text
    return (
      <div className="gdpr">
        <h3>GDPR</h3>
        <p className="font-weight-bold">
          If you want to delete your FreeStyle Progress details permanently please contact us using any of the following details:
        </p>

        <p>Please call the customer careline on:</p>
        <p>
          UK: <a href="tel:+4408001701177">0800 170 1177</a> (8am-8pm Monday to Friday)
        </p>
        <p>
          Ireland: <a href="tel:+3531800776633">1 800 776633</a> (8am-5:30pm Monday to Friday)
        </p>
        <p>
          or email <a href="mailto:adchelpuk@abbott.com">adchelpuk@abbott.com</a>
        </p>
      </div>
    );
  }
}

// Proptypes
GDPR.propTypes = {
  onConfirmRequest: PropTypes.func.isRequired,
};

export default GDPR;
