import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadVideoGalleries } from '../../actions/video-gallery-actions';

import ReactImageVideoLightbox from 'react-image-video-lightbox';

// https://stackoverflow.com/a/8260383
const getYouTubeID = url => {
  // eslint-disable-next-line no-useless-escape
  const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/;
  const match = url.match(regExp);
  return match && match[7].length === 11 ? match[7] : false;
};

const thumbnail = item => {
  if (item.youtubeUrl) {
    const id = getYouTubeID(item.youtubeUrl);
    return `https://i3.ytimg.com/vi/${id}/hqdefault.jpg`;
  }

  return item.image;
};

class VideoGallery extends Component {
  static propTypes = {
    id: PropTypes.number,
    videoGalleries: PropTypes.object,
  };

  constructor(props) {
    super(props);

    // Set the default states
    this.state = {
      lightbox: false,
      slide: 0,
    };

    this.lightboxOpen = this.lightboxOpen.bind(this);
  }

  lightboxOpen(slide) {
    this.setState(state => ({
      lightbox: !state.lightbox,
      slide,
    }));
  }

  render() {
    let items;
    try {
      items = this.props.videoGalleries[this.props.id].acf.items;
    } catch (e) {
      console.warn(`Items not found for video gallery '${this.props.id}'`);
      console.dir(this.props.videoGalleries[this.props.id]);
    }
    if (!items) {
      return <div></div>;
    }

    const lightboxData = items.map(item => ({
      url: item.youtubeUrl ? `https://www.youtube-nocookie.com/embed/${getYouTubeID(item.youtubeUrl)}` : item.image,
      type: item.youtubeUrl ? 'video' : 'photo',
      altTag: item.title,
    }));
    // console.log(urls);

    const title = this.props.videoGalleries[this.props.id].acf.displayTitle;
    const description = this.props.videoGalleries[this.props.id].acf.displayDescription;

    return (
      <div className="videogallery">
        <h1 className={'video-gallery-title ' + (description ? 'withdesc' : '')}>{title}</h1>
        {description && <h2 className="video-gallery-description">{description}</h2>}
        <ul className="video-gallery">
          {items.map((item, i) => (
            <li onClick={() => this.lightboxOpen(i)} key={i.toString()}>
              <img src={thumbnail(item)} alt={item.title} />
              <div className="text">
                <h3>{item.title}</h3>
                <div className="description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
              </div>
            </li>
          ))}
        </ul>
        {this.state.lightbox && (
          <div className="lightbox">
            <ReactImageVideoLightbox
              data={lightboxData}
              startIndex={this.state.slide}
              showResourceCount={true}
              onCloseCallback={() => this.setState({ lightbox: false })}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { videoGalleries },
  } = state;

  return {
    videoGalleries,
  };
};

export default connect(mapStateToProps, {
  loadVideoGalleries,
})(VideoGallery);
