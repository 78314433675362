import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPage } from '../actions/pages-actions';

class Page404 extends Component {
  render() {
    return (
      <div className="page error-page">
        <div className="pt-5 pb-5 container">
            <div className="row align-items-center justify-content-md-center">
              <div className="col-12 col-sm-4 col-md-3">
                <div className='octagon'>
                  <span>404</span>
                </div>
              </div>
              <div className="col-12 col-sm-8 col-md-5">
                <h1>Error!</h1>
                <h2>We couldn't find this page</h2>
              </div>
            </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  return {}
}

export default withRouter(connect(mapStateToProps, {
  loadPage
})(Page404))
