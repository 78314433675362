import { CALL_API } from '../middleware/api';

/**
 * Token
 */
export const TOKEN_REQUEST = 'TOKEN_REQUEST';
export const TOKEN_SUCCESS = 'TOKEN_SUCCESS';
export const TOKEN_FAILURE = 'TOKEN_FAILURE';

const fetchToken = (username, password) => ({
  username,
  password,
  [CALL_API]: {
    types: [TOKEN_REQUEST, TOKEN_SUCCESS, TOKEN_FAILURE],
    endpoint: 'jwt-auth/v1/token',
    method: 'POST',
    body: {
      username,
      password,
    },
  },
});

export const loadToken = (username, password) => dispatch => dispatch(fetchToken(username, password));

/**
 * Login
 */

export const VALIDATE_REQUEST = 'VALIDATE_REQUEST';
export const VALIDATE_SUCCESS = 'VALIDATE_SUCCESS';
export const VALIDATE_FAILURE = 'VALIDATE_FAILURE';

const fetchValidate = () => ({
  [CALL_API]: {
    types: [VALIDATE_REQUEST, VALIDATE_SUCCESS, VALIDATE_FAILURE],
    endpoint: 'jwt-auth/v1/token/validate',
    method: 'POST',
  },
});

export const loadValidate = () => dispatch => dispatch(fetchValidate());

/**
 * Logout
 */

export const LOGOUT = 'LOGOUT';

export const logout = () => dispatch => dispatch({
  type: LOGOUT,
});

/**
 * Register
 */
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';

const fetchRegister = body => ({
  [CALL_API]: {
    types: [REGISTER_REQUEST, REGISTER_SUCCESS, REGISTER_FAILURE],
    endpoint: 'abbott/v1/users/sign-up',
    method: 'POST',
    body,
  },
});

export const doRegistration = data => dispatch => dispatch(fetchRegister(data));


/**
 * Register
 */
export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

const fetchKeyReset = email => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    endpoint: 'abbott/v1/password-reset',
    method: 'POST',
    body: {
      email,
    },
  },
});

const fetchPasswordReset = data => ({
  [CALL_API]: {
    types: [RESET_PASSWORD_REQUEST, RESET_PASSWORD_SUCCESS, RESET_PASSWORD_FAILURE],
    endpoint: 'abbott/v1/password-reset',
    method: 'POST',
    body: data,
  },
});

export const doKeyReset = data => dispatch => dispatch(fetchKeyReset(data));
export const doPasswordReset = data => dispatch => dispatch(fetchPasswordReset(data));


export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE';

const fetchPasswordChange = data => ({
  [CALL_API]: {
    types: [CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_SUCCESS, CHANGE_PASSWORD_FAILURE],
    endpoint: 'abbott/v1/password-change',
    method: 'POST',
    body: data,
  },
});

export const doPasswordChange = data => dispatch => dispatch(fetchPasswordChange(data));
