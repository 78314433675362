import merge from 'lodash/merge';
import * as AuthenticationActionTypes from '../actions/authentication-actions';

// Authentication initial state
const authenticationInitialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  token: '',
  tokenExpiration: 0,
};

const token = localStorage.getItem('token');
const tokenExpiration = localStorage.getItem('tokenExpiration');

// Check if there is local stored token
if (token) {
  // Todo: check for expiration
  authenticationInitialState.isAuthenticated = true;
  authenticationInitialState.token = token;
  authenticationInitialState.tokenExpiration = tokenExpiration;
}

// Updates the authenticated state in response to any token or validate request
const authentication = (state = authenticationInitialState, action) => {
  const { type } = action;

  // Get current token from localStorage
  const currentToken = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;

  // If Offline and we have a token set alreadyAuthenticated to true
  const alreadyAuthenticated = !!currentToken;

  // Doing the reducing
  switch (type) {
    case AuthenticationActionTypes.TOKEN_REQUEST:
    case AuthenticationActionTypes.VALIDATE_REQUEST:
      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated,
        isAuthenticating: true,
      });
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      // Save to the local storage
      localStorage.setItem('token', action.response.token);
      localStorage.setItem('tokenExpiration', action.response.expiration);

      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false,
        expiration: action.response.expiration,
      });
    case AuthenticationActionTypes.VALIDATE_SUCCESS:
      return merge({}, state, {
        isAuthenticated: true,
        isAuthenticating: false,
      });
    case AuthenticationActionTypes.TOKEN_FAILURE:
    case AuthenticationActionTypes.VALIDATE_FAILURE:
      // Remove the token and expiration on failure
      if (!alreadyAuthenticated || navigator.onLine) {
        localStorage.removeItem('token');
        localStorage.removeItem('tokenExpiration');
      }

      return merge({}, state, {
        isAuthenticated: alreadyAuthenticated || !navigator.onLine,
        isAuthenticating: false,
      });
    case AuthenticationActionTypes.LOGOUT:
      // Remove from local storage
      localStorage.removeItem('token');
      localStorage.removeItem('tokenExpiration');

      return merge({}, state, {
        isAuthenticated: false,
        isAuthenticating: false,
        token: '',
        tokenExpiration: 0,
      });
    default:
  }
  return state;
};

export default authentication;
