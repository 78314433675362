import { includes } from 'lodash/collection';
import { isEmpty } from 'lodash/lang';
import { values } from 'lodash/object';
import { HEARTBEAT } from './actions';
import { ADMIN_DOMAIN } from './config';

// Strip url host name
function stripUrlHostname(rawUrl) {
  // If there is no url or not start with http
  if (!rawUrl || rawUrl.indexOf('http') !== 0) {
    return rawUrl;
  }

  // Use the URL api if the browser supports it
  if (URL) {
    const parsedUrl = new URL(rawUrl);
    return parsedUrl.href.replace(parsedUrl.origin, '');
  }
  // Use a tag if browser not supported URL
  const a = document.createElement('a');
  a.href = rawUrl;
  return rawUrl.replace(a.origin, '');
}

const knowDomains = [
  'stage-admin.freestylediabetes.co.uk', // Staging domain
  'admin.freestylediabetes.co.uk', // Live domain
  'localhost:8080', // Local
  'localhost:3000',
];

// Strip api domains
function stripUrlKnownDomain(rawUrl) {
  // Check if the rawUrl contain api domains or not
  let newUrl = rawUrl;
  for (let i = 0, size = knowDomains.length; i < size; i++) {
    const d = knowDomains[i];
    // Do replace when it contain api domain
    if (newUrl.indexOf(d) !== -1) {
      // Get rid of the http or https first
      newUrl = newUrl.replace(d, '').replace(/http[s]?:\/\//, '');

      break;
    }
  }

  return newUrl;
}

// Find tab post
function findTabPostForPage(posts, page) {
  // Check if the page does contain tabblock or not
  // Set the post name
  if (!page || !page.acf || !page.acf.tabBlock || !page.acf.tabBlock.postName) {
    return false;
  }
  const postName = page.acf.tabBlock.postName;

  // Get the posts with equal post slug
  const keys = Object.keys(posts).filter(postSlug => postSlug === postName);

  if (keys.length > 0) {
    // There shoiuld be only one!
    return posts[keys[0]];
  }
  return false;
}

// Find Tab data for page
function findTabDataForPage(posts, page) {
  const tabPost = findTabPostForPage(posts, page);

  if (tabPost) {
    return tabPost.acf.tabbedArea;
  }
  return false;
}

// Check if the email has allow domains
function emailHazAllowDomains(email, allowDomains) {
  const emailParts = email.toLowerCase().split('@');
  const domain = emailParts[1];

  // Filter the domain
  const matchedDomains = allowDomains.filter(allowDomain => domain.match(allowDomain));
  // Its valid if there is matched domain
  return matchedDomains.length > 0;
}

// Specialised function that will only check for hcp allow
// So the original function can be use by others
function isHcpAllowEmail(email) {
  // todo: Allow domains
  // IF YOU CHANGE HERE, PLEASE ALSO CHANGE THE WORDPRESS SITE AS WELL
  // AS THIS IS THE SAME VALIDATION CHECK ON REGISTRATION ON SERVER/WORDPRESS SIDE
  const allowDomains = [
    'nhs.uk',
    'nhs.net',
    'nhs.scot',
    '24sq.com',
    'abbott.com',
    /\.ie$/, // Anything end with .ie
    /\.hscni.net$/, // Allow subdomains for .hscni.net
    'nobles.dhss.gov.im',
    'gov.gg',
    'diabetes.org.uk',
    'thelondonclinic.co.uk',
    'thewellnessjunction.com',
    'bramacare.co.uk',
    'livahealthcare.com',
    'fpctramore.com',
    'livahealth.com',
    'hscni.net',
    'gov.gg',
    'health.gov.je',
    'locala.org',
    'greensleeves.org.uk',
  ];
  return emailHazAllowDomains(email, allowDomains);
}

function showOrderingPage(user, page) {
  const type = user.meta.type;
  const email = user.email;

  if (type === 'hcp' && page === 'Ordering') {
    return isHcpAllowEmail(email);
  }

  return true;
}

/**
 * DON'T USE IT AS IT IS FOR NOW
 * Heartbeat check
 * See if there is connection
 * The Wordpress heartbeat api will return server time
 * todo: NONCE Will need some work
 */
function heartbeatCheck(store) {
  // Count accumulated failed
  let accumulatedFailed = 0;
  // The delay, it can be increase if failed a lot
  const delay = 15000;

  // Check api root
  const checkApiRoot = async function () {
    try {
      const formBody = ['action=heartbeat', '_nonce=fdfdsfdsfdf'];

      await fetch(`${ADMIN_DOMAIN}/wp-admin/admin-ajax.php`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
        },
        body: formBody.join('&'),
      });

      // Set the payload to the returned server time
      store.dispatch({
        type: HEARTBEAT,
        payload: true,
      });

      // Reset the accumulated faileds
      accumulatedFailed = 0;
    } catch (error) {
      // Cant not connect the the admin domain
      console.error('heartbeat check error', error);
      store.dispatch({
        type: HEARTBEAT,
        payload: false,
      });

      // Increate the accumulated failed
      accumulatedFailed += 1;
    }

    // Logic to see if need to make the delay call to heartbeat again
    // e.g if accumulately failed in the last 10 tries,
    // it will be good idea to make the delay longer - no need to drain battery
    const extraDelay = Math.floor(accumulatedFailed / 10) * 10000;

    // Call itself again
    setTimeout(() => {
      checkApiRoot();
    }, delay + extraDelay);
  };

  // Check the heart beat every so often
  // and dispatch the appropiate actions
  setTimeout(() => {
    checkApiRoot();
  }, delay);
}

/**
 * isMobile - detecth mobile browser
 * https://stackoverflow.com/questions/11381673/detecting-a-mobile-browser
 */
function isMobile() {
  let check = false;
  // eslint-disable-next-line no-useless-escape
  (function (a) {
    if (
      /(ipad|android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a,
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4),
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  // don't treat landscape iPad as mobile
  // if ((window.screen.orientation.angle === 90 || window.screen.orientation.angle === -90) && navigator.userAgent.match(/iPad/i) != null) check = false;
  if ((window.orientation === 90 || window.orientation === -90) && navigator.userAgent.match(/iPad/i) != null) {
    check = false;
  }

  return check;
}

/**
 * Check if the content contains a table and add class to it if it does
 */
function containsTable(content, classes) {
  if (content.indexOf('<table') !== -1) {
    classes.push('contain-table');
  }
}

/**
 * Find role id for user
 */
function findRoleIdForUser(user, roles) {
  // find the user type
  const userType = user && user.meta && user.meta.type ? user.meta.type : '';

  // Check if there is roles or user type or not
  if (userType === '' || Object.keys(roles).length === 0) {
    return false;
  }

  // Find the role
  const theRoles = Object.values(roles).filter(r => r.slug === userType);
  if (theRoles.length > 0) {
    return theRoles[0].id;
  }
  return false;
}

const findCountryIdForUser = (user, map) => {
  const userCountry = user && user.meta ? user.meta.country : '';

  // Fallback to UK if the user has no country
  if (userCountry === '') {
    return map.uk;
  }

  return map[userCountry];
};

const filterPostsByCountryAndRole = (posts, roleId, countryId, postType) => {
  const results = values(posts)
    .filter(post => post.type === postType)
    .filter(post => !isEmpty(post.roles))
    .filter(post => !isEmpty(post.countries))
    .filter(post => includes(post.roles, roleId))
    .filter(post => includes(post.countries, countryId));

  return results;
};

const formatBritishPostcode = postcode => {
  const result = postcode.match(/^([A-Za-z]{1,2}\d{1,2}[A-Za-z]?)\s*(\d[A-Za-z]{2})$/);

  if (result === null) {
    return postcode;
  }

  result.shift();

  return result.join(' ').toUpperCase();
};

export {
  findRoleIdForUser,
  findCountryIdForUser,
  filterPostsByCountryAndRole,
  formatBritishPostcode,
  isHcpAllowEmail,
  emailHazAllowDomains,
  stripUrlHostname,
  stripUrlKnownDomain,
  findTabDataForPage,
  heartbeatCheck,
  isMobile,
  containsTable,
  showOrderingPage,
};
