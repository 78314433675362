import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadCtaBlocks } from '../../actions/cta-block-actions';

class CtaBlock extends Component {
  static propTypes = {
    id: PropTypes.number,
    ctaBlocks: PropTypes.object,
  };

  blockWidth = (param) => {
    switch (param) {
      case 'onethird':
        return 'col-sm-12 col-lg-4';
      case 'twothird':
        return 'col-sm-12 col-lg-8';
      case 'half':
        return 'col-sm-12 col-lg-6';
      default:
        return '';
    }
  }

  buttonRender = (params) => {
    if(params.colorMode[0] === "invert") {
      return (<a href={params.url} className="btn btn-white" target={params.target}>{params.label}</a>);
    } else {
      return (<a href={params.url} className="btn btn-orange" target={params.target}>{params.label}</a>);
    }
  }

  render() {

    let buttons;
    try {
      buttons = this.props.ctaBlocks[this.props.id].acf.buttons;
    } catch(e) {
      console.warn(`Buttons not found for cta block '${this.props.id}'`);
      console.dir(this.props.ctaBlocks[this.props.id]);
    }

    return (
      <div className="p-4 col-12 ctablock" style={{backgroundImage: 'url(' + this.props.ctaBlocks[this.props.id].acf.backgroundImage.url + ')'}} >
        <div className={'content-container pt-3 ' + this.blockWidth(this.props.ctaBlocks[this.props.id].acf.width)}>
          <h2>{this.props.ctaBlocks[this.props.id].acf.title}</h2>
          <div className="content">{this.props.ctaBlocks[this.props.id].acf.content}</div>
          <div className="buttons">
            {buttons.map((button, i) => (
              this.buttonRender(button)
            ))}
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { ctaBlocks },
  } = state;

  return {
    ctaBlocks,
  }
}

export default connect(mapStateToProps, {
  loadCtaBlocks
})(CtaBlock)
