import { CALL_API } from '../middleware/api';

export const REVIEW_REQUEST = 'REVIEW_REQUEST';
export const REVIEW_SUCCESS = 'REVIEW_SUCCESS';
export const REVIEW_FAILURE = 'REVIEW_FAILURE';

const fetchReview = payload => ({
  [CALL_API]: {
    types: [REVIEW_REQUEST, REVIEW_SUCCESS, REVIEW_FAILURE],
    endpoint: 'abbott/v1/reviews',
    method: 'POST',
    body: payload,
  },
});

export const loadReview = payload => (dispatch, getState) => dispatch(fetchReview(payload));
