import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { doPasswordChange } from '../actions/authentication-actions'
import { loadUser} from '../actions/users-actions'

import Loader from '../components/Loader'
import BannerRow from '../components/pages/BannerRow'

class ChangePasswordPage extends Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    doPasswordChange: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    let search = window.location.search.substr(1).split('|');
    if (search.length !== 2) {
      search = [null, null];
    }

    this.state = {
      error: false,
      loading: false,
      success: false,
    }

    this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.renderPasswordForm = this.renderPasswordForm.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false })
  }


  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
  }

  onConfirmEdit(event) {
    const elNew = document.getElementById('passwordNew');
    const elConfirm = document.getElementById('passwordConfirm');
    if (elNew.value !== elConfirm.value) {
      elConfirm.setCustomValidity('Passwords must match');
    }
    else {
      elConfirm.setCustomValidity('');
    }
  }

  onPasswordSubmit(event) {
    event.preventDefault();
    console.log(this.refs);
    if (event.target.checkValidity() !== false) {
      if (this.refs.passwordOld.value && this.refs.passwordNew.value && this.refs.passwordConfirm.value && (this.refs.passwordNew.value === this.refs.passwordConfirm.value)) {
        this.setState({
          error: false,
          loading: true,
          success: false
        })
        this.props.doPasswordChange({ old: this.refs.passwordOld.value, new: this.refs.passwordNew.value })
          .then((response) => {
            if (response.error) {
              this.setState({loading: false, error: response.error});
            } else {
              this.setState({loading: false, success: true});
            }
          });
      }
    }
    event.target.classList.add('was-validated');
  }

  renderPasswordForm() {
    const { authentication: {isAuthenticating}} = this.props;

    return (
      <div className="inner-block bg-light">
        {!this.state.success && (
          <form className="needs-validation bg-faded" onSubmit={this.onPasswordSubmit}>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="passwordOld">Old password</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="passwordOld" placeholder="••••••••" ref="passwordOld" required />
                <div className="invalid-feedback">
                  Please enter a password.
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="passwordNew">New password</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="passwordNew" placeholder="••••••••" ref="passwordNew" required />
                <div className="invalid-feedback">
                  Please enter a password.
                </div>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="passwordConfirm">New password (confirmation)</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="passwordConfirm" placeholder="••••••••" ref="passwordConfirm" onChange={this.onConfirmEdit} required />
                <div className="invalid-feedback">
                  Passwords must match.
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-8 offset-sm-4">
                <button type="submit" className={'btn btn-orange ' + (this.state.loading ? 'disabled' : '')}>
                  Submit
                  {isAuthenticating && (
                    <Loader />
                  )}
                </button>
              </div>
            </div>
          </form>
        )}

        {this.state.error && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{__html: this.state.error}}></div>
            <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {this.state.success && (
          <div>
            <div className="alert alert-success fade show" role="alert">
              <div><strong>Password changed successfully.</strong></div>
            </div>
            <p><Link to="/">&gt; Home</Link></p>
          </div>
        )}
      </div>
    )
  }

  render() {
    // const { authentication } = this.props;

    const banner = {
      content: "<h1>CHANGE PASSWORD</h1><p>Enter your new password below.</p>"
    }

    // const link = '/sign-in/'

    // if (this.state.success || authentication.isAuthenticated) {
    //   return (
    //     <Redirect to={link} />
    //   )
    // }

    return (
      <div className="page login">
        <BannerRow banner={banner} />
        <div className="pt-3 pb-3 container">
          <div className="row">
            <div className="outer-block col-12 bg-dark">
              {this.renderPasswordForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication
  } = state

  return {
    authentication
  }
}

export default withRouter(connect(mapStateToProps, {
  doPasswordChange,
  loadUser
})(ChangePasswordPage))
