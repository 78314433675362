import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadPost } from '../actions/posts-actions';
import { loadUser } from '../actions/users-actions';

import BannerRow from '../components/pages/BannerRow';
import Loader from '../components/Loader';
import Module from '../components/modules';
import Post from '../components/Posts';
import CaseStudyBox from '../components/Box/CaseStudy'

import find from 'lodash/find';

const loadData = ({ postTypeSlug, slug, loadPost, loadUser }) => {
  loadPost(postTypeSlug, slug);
  loadUser('me');
};

class PostPage extends Component {
  static propTypes = {
    postTypeSlug: PropTypes.string,
    slug: PropTypes.string.isRequired,
    post: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    loadPost: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
  };

  componentWillMount() {
    loadData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.slug !== this.props.slug) {
      loadData(nextProps);
    }
  }

  renderPost(post, user) {
    const { type } = post;
    switch (type) {
      case 'casestudies':
        return <CaseStudyBox data={post} {...this.props}  />;
      case 'modules':
        return <Module post={post} user={user} />;
      default:
        return <Post {...this.props} post={post} user={user} />;
    }
  }

  render() {
    const { post, user } = this.props;

    // Show loading if there is no post
    if (!post) {
      return <Loader />;
    }

    // Set the class name
    const className = ['post', post.type];

    return (
      <div className={className.join(' ')}>
        {post.acf && post.acf.banner && post.acf.banner && <BannerRow banner={post.acf.banner} />}
        <div className="container">{this.renderPost(post, user)}</div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  // We need to lower case the slug due to the way GitHub's API behaves.
  // Have a look at ../middleware/api.js for more details.
  const postTypeSlug = (ownProps.match.params.postTypeSlug || 'posts').toLowerCase();
  const slug = ownProps.match.params.slug.toLowerCase();

  const {
    entities: { roles, posts, countries },
    user,
  } = state;

  //const post = posts[slug]
  const type = user && user.meta && user.meta.type ? user.meta.type : '';

  let currentRole = find(roles, role => {
    return role.slug === type;
  });

  const post = find(posts, post => {
    if (post.slug === slug) {
      if (currentRole) {
        return post.roles.indexOf(currentRole.id) > -1;
      } else {
        return post.roles.length === 0;
      }
    } else {
      return false;
    }
  });

  // Filter out by user country
  if (post && Array.isArray(post.countries) && post.countries.length > 0) {
    let found = false;
    for (const country of post.countries) {
      if (countries[country].slug === user.meta.country) {
        found = true;
      }
    }
    if (!found) return false;
  }

  return {
    postTypeSlug,
    slug,
    post,
    user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPost,
    loadUser,
  })(PostPage),
);
