import React, { Component } from 'react';
import PropTypes from 'prop-types'
import QuestionsComp from './index';

// This is the key for the acf field
const IS_CORRECT_ANSWER_KEY = 'isItTheCorrectAnswer';
class Questions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAnswers: props.selectedAnswers,
    };

    // bind the functions
    this.selectAnswer = this.selectAnswer.bind(this)
    this.resetAnswers = this.resetAnswers.bind(this);
  }

  resetAnswers() {
    // Save the progress
    this.props.onSaveProgress({});

    // Reset the state
    this.setState({
      selectedAnswers: {},
    });
  }

  selectAnswer(parentQuestion, selectedAnswer) {
    const {
      questions,
      onSaveProgress,
    } = this.props;
    const { selectedAnswers } = this.state;
    const answer = questions[parentQuestion].answers[selectedAnswer];
    const isCorrect = answer[IS_CORRECT_ANSWER_KEY];

    // Set the answer
    selectedAnswers[`question${parentQuestion}`] = {
      correct: isCorrect !== '' ? 'correct' : 'incorrect',
      selectedAnswer,
    }

    // Save the progress
    onSaveProgress(selectedAnswers);

    // Set the state
    this.setState({
      selectedAnswers,
    });
  }

  render() {
    const { questions } = this.props;
    const {
      selectedAnswers,
    } = this.state;

    return (
      <QuestionsComp
        questions={questions}
        isCorrectAnswerKey={IS_CORRECT_ANSWER_KEY}
        selectedAnswers={selectedAnswers}
        onSelectAnswer={this.selectAnswer}
        onResetAnswers={this.resetAnswers}
      />
    );
  }
}

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  selectAnswers: PropTypes.object,
  onSaveProgress: PropTypes.func,
}

Questions.defaultProps = {
  onSaveProgress: () => {},
}

export default Questions;
