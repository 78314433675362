const ADMIN_DOMAIN = process.env.REACT_APP_ADMIN_DOMAIN || 'http://localhost:8080';

if (process.env.REACT_APP_STAGING) {
  console.log('the process environment variables', process.env);
}

const API_ROOT = `${ADMIN_DOMAIN}/wp-json/`;

export {
  ADMIN_DOMAIN,
  API_ROOT,
};
