import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { doKeyReset, doPasswordReset } from '../actions/authentication-actions'
import { loadUser} from '../actions/users-actions'

import Loader from '../components/Loader'
import BannerRow from '../components/pages/BannerRow'

class ResetPasswordPage extends Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    doKeyReset: PropTypes.func.isRequired,
    doPasswordReset: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
  }

  constructor(props) {
    super(props);

    let search = decodeURIComponent(window.location.search.substr(1)).split('|');
    if (search.length !== 2) {
      search = [null, null];
    }

    this.state = {
      error: false,
      loading: false,
      success: false,
      key: search[0],
      keyLogin: search[1],
    }

    this.onKeySubmit = this.onKeySubmit.bind(this);
    this.onPasswordSubmit = this.onPasswordSubmit.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.renderKeyForm = this.renderKeyForm.bind(this);
    this.renderPasswordForm = this.renderPasswordForm.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false })
  }


  componentWillMount() {

  }

  componentWillReceiveProps(nextProps) {
  }

  onKeySubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity() !== false) {
      if (this.refs.username.value) {
        this.setState({
          error: false,
          loading: true,
          success: false
        })
        this.props.doKeyReset(this.refs.username.value)
          .then((response) => {
            if (response.error) {
              this.setState({loading: false, error: response.error});
            } else {
              this.setState({loading: false, success: true});
            }
          });
      }
    }
    event.target.classList.add('was-validated');
  }

  onPasswordSubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity() !== false) {
      if (this.refs.password.value) {
        this.setState({
          error: false,
          loading: true,
          success: false
        })
        this.props.doPasswordReset({ login: this.state.keyLogin, key: this.state.key, password: this.refs.password.value })
          .then((response) => {
            if (response.error) {
              this.setState({loading: false, error: response.error});
            } else {
              this.setState({loading: false, success: true});
            }
          });
      }
    }
    event.target.classList.add('was-validated');
  }

  renderKeyForm() {
    const { authentication: {isAuthenticating}} = this.props;

    return (
      <div className="inner-block bg-light">
        {!this.state.success && (
          <form className="needs-validation bg-faded" onSubmit={this.onKeySubmit} noValidate >
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="loginUsername">Email</label>
              <div className="col-sm-8">
                <input type="email" className="form-control" id="loginUsername" placeholder="you@example.com" ref="username" autoComplete="username" required />
                <div className="invalid-feedback">
                  Please enter an email.
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-8 offset-sm-4">
                <button type="submit" className={'btn btn-orange ' + (this.state.loading ? 'disabled' : '')}>
                  Submit
                  {isAuthenticating && (
                    <Loader />
                  )}
                </button>
              </div>
            </div>
          </form>
        )}

        {this.state.error && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{__html: this.state.error}}></div>
            <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {this.state.success && (
          <div className="alert alert-success fade show" role="alert">
            <div><strong>Success.</strong> If you are registered user, we've emailed you. Click the link in the email to reset your password.</div>
          </div>
        )}
      </div>
    )
  }

  renderPasswordForm() {
    const { authentication: {isAuthenticating}} = this.props;

    return (
      <div className="inner-block bg-light">
        {!this.state.success && (
          <form className="needs-validation bg-faded" onSubmit={this.onPasswordSubmit} noValidate >
            <div className="form-group row">
              <label className="col-sm-4 col-form-label" htmlFor="loginPassword">New password</label>
              <div className="col-sm-8">
                <input type="password" className="form-control" id="loginPassword" placeholder="••••••••" ref="password" required />
                <div className="invalid-feedback">
                  Please enter a password.
                </div>
              </div>
            </div>
            <div className="form-group row">
              <div className="col-sm-8 offset-sm-4">
                <button type="submit" className={'btn btn-orange ' + (this.state.loading ? 'disabled' : '')}>
                  Submit
                  {isAuthenticating && (
                    <Loader />
                  )}
                </button>
              </div>
            </div>
          </form>
        )}

        {this.state.error && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{__html: this.state.error}}></div>
            <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}

        {this.state.success && (
          <div>
            <div className="alert alert-success fade show" role="alert">
              <div><strong>Password reset successfully.</strong></div>
            </div>
            <p><Link to="/sign-in">&gt; Sign in with your new password</Link></p>
          </div>
        )}
      </div>
    )
  }

  render() {
    // const { authentication } = this.props;

    const banner = {
      content: "<h1>RESET PASSWORD</h1><p>" + (this.state.key ? "Enter your new password below." : "<p>Don’t have an account? <a href='/sign-up'>Register here</a>") + "</p>"
    }

    // const link = '/sign-in/'

    // if (this.state.success || authentication.isAuthenticated) {
    //   return (
    //     <Redirect to={link} />
    //   )
    // }

    return (
      <div className="page login">
        <BannerRow banner={banner} />
        <div className="pt-3 pb-3 container">
          <div className="row">
            <div className="outer-block col-12 bg-dark">
              {this.state.key ? this.renderPasswordForm() : this.renderKeyForm()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    authentication
  } = state

  return {
    authentication
  }
}

export default withRouter(connect(mapStateToProps, {
  doKeyReset,
  doPasswordReset,
  loadUser
})(ResetPasswordPage))
