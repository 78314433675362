import React, { Component } from 'react';

class TermsConditions extends Component {
  constructor(props) {
    super(props);

    let checklistState = {}
    if (props.checklist) {
      props.checklist.forEach(item => checklistState[item.name] = false);
    }

    this.state = {
      checklistState,
      error: false,
    }

    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onCheckboxChange(event) {
    const { checklistState } = this.state;
    checklistState[event.target.name] = event.target.checked;

    this.setState({
      checklistState,
      error: false,
    });
  }

  onSubmit() {
    const { checklistState } = this.state;

    // Check if they all checked or not
    const notChecks = Object.values(checklistState).filter(i => !i);
    if (notChecks.length === 0) {
      this.props.onAccept();
    } else {
      this.setState({
        error: 'Please check all the boxes',
      })
    }
  }

  render() {
    const {
      content,
      checklist,
      smallPrint,
    } = this.props;

    const {
      error
    } = this.state;

    return (
      <div className="pt-3 pb-3 container terms-conditions">
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="mb-4" dangerouslySetInnerHTML={{__html: content}}></div>

            {checklist && checklist.map((item, index) => (
              <div key={index} className="custom-control custom-checkbox custom-control-inline checkbox-wrapper form-group">
                <input id={`checkbox-${item.name}`} className="custom-control-input" type="checkbox" name={item.name} value={item.value} onChange={this.onCheckboxChange} />
                <label className="custom-control-label" htmlFor={`checkbox-${item.name}`} dangerouslySetInnerHTML={{__html: item.value}}>
                </label>
              </div>
            ))}

            <div className="mt-2">
              <button className="btn btn-orange" type="submit" onClick={this.onSubmit}>Submit</button>
            </div>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}

						{smallPrint && (
              <div className="mt-4" dangerouslySetInnerHTML={{__html: smallPrint}}></div>
						)}
          </div>
        </div>
      </div>
    )
  }
}

export default TermsConditions;
