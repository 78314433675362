import React from 'react';
import PropTypes from 'prop-types';
import Disclaimer from '../Disclaimers';

// Functional component - just output post without state
const Post = ({ post, user, history }) => {
  return (
    <div className="post-wrapper">
      <div className="post-header flex-space-between">
        <h2>{post.title.rendered}</h2>

        <button className="btn btn-orange back-button" onClick={() => history.goBack()}>
          Back
        </button>
      </div>

      <div className="page-content" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>

      <div className="page-content post-columns">
        {typeof post.acf.columns === 'object' &&
          post.acf.columns.map(column => (
            <div className="row">
              <div
                className={'post-column left col-md-' + (column.right ? '6' : '12')}
                dangerouslySetInnerHTML={{ __html: column.left }}
              ></div>
              {column.right && <div className="post-column right col-md-6" dangerouslySetInnerHTML={{ __html: column.right }}></div>}
            </div>
          ))}

        {(post.acf.disclaimerList || post.acf.references) && (
          <div id="accordion" class="disclaimers">
            <div className="card">
              <div
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                className="card-header"
                id="headingOne"
              >
                <div className="col-12">
                  <h2 className="mb-0">References &amp; Disclaimers</h2>
                </div>
              </div>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                  <div className="col-12">
                    {post.acf.disclaimerList ? (
                      <ul>
                        {post.acf.disclaimerList &&
                          post.acf.disclaimerList.map((item, i) =>
                            item.reference && item.disclaimer ? (
                              <li key={i.toString()}>
                                {item.reference} <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : item.reference ? (
                              <li key={i.toString()}>{item.reference}</li>
                            ) : item.disclaimer ? (
                              <li key={i.toString()}>
                                <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : null,
                          )}
                      </ul>
                    ) : (
                      post.acf.references && <div dangerouslySetInnerHTML={{ __html: post.acf.references }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

// Props
Post.propTypes = {
  post: PropTypes.object,
};

export default Post;
