import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { findTabDataForPage, containsTable } from '../../utils';
import { loadPost } from '../../actions/posts-actions';
import { updateUserMeta } from '../../actions/progress-actions';
import Tabs from '../Tabs/React.jsx';
import Slides from '../Slides';
import Questions from '../Questions/Container';
import Disclaimer from '../Disclaimers';

// User meta key for the agp answers
// MAKE SURE YOU CHANGE THE KEY ON SERVER if you making change in here!!!!!
const AGP_ANSWERS_META_KEY = 'agpAnswers';

class AGPPageTemplate extends Component {
  constructor(props) {
    super(props);

    this.getSlides = this.getSlides.bind(this);
    this.saveProgress = this.saveProgress.bind(this);
  }

  componentWillMount() {
    const { page } = this.props;
    const tabBlock = page.acf.tabBlock;
    if (tabBlock) {
      this.props.loadPost('tab_content_block', tabBlock.postName);
    }
  }

  // Save the progress
  saveProgress(questionKey, data) {
    const { user, updateUserMeta } = this.props;

    // Get the user agpAnswers meta
    const savedAnswers = user[AGP_ANSWERS_META_KEY] || {};

    // Add quesiton key
    savedAnswers[questionKey] = data;

    updateUserMeta(user.id, AGP_ANSWERS_META_KEY, savedAnswers);
  }

  // Find the test cases or transform them if needed
  getSlides(page, user) {
    // Construct the slides
    const slides = [];

    // Get the user agpAnswers meta
    const savedAnswers = user[AGP_ANSWERS_META_KEY] || {};

    // Render the sides for the page
    if (page && page.acf && page.acf.testCases) {
      let slideIndex = 0;
      // Combine all the case studies into slides
      page.acf.testCases.forEach((testCase, testCaseIndex) => {
        // Put the content into slides
        if (testCase.sections) {
          testCase.sections.forEach(section => {
            slides.push(
              <div key={slideIndex++} className="slide user-content">
                <h3 className="bottom-line">{testCase.title}</h3>
                <div dangerouslySetInnerHTML={{ __html: section.content }}></div>
              </div>,
            );
          });
        }

        // Put the question slide in the last as well
        if (testCase.questions) {
          // Get selected answer
          const selectedAnswers = savedAnswers[testCaseIndex] || {};

          slides.push(
            <Questions
              questions={testCase.questions}
              key={slideIndex++}
              selectedAnswers={selectedAnswers}
              onSaveProgress={data => this.saveProgress(testCaseIndex, data)}
            />,
          );
        }
      });
    }

    return slides;
  }

  getNewSlides(page, user) {
    // Construct the slides
    const slides = [];
    const slidesKey = 'slide';

    // Get the user agpAnswers meta
    const savedAnswers = user[AGP_ANSWERS_META_KEY] || {};

    // Render the sides for the page
    if (page && page.acf && page.acf[slidesKey]) {
      // Combine all the case studies into slides
      page.acf[slidesKey].forEach((slide, slideIndex) => {
        // Check the slide type
        // content is simply content
        if (slide.type === 'Content') {
          const classNames = ['user-content'];

          // check if the tab content have table
          containsTable(slide.content, classNames);

          slides.push(
            <div key={slideIndex} className="slide">
              <div className={classNames.join(' ')} dangerouslySetInnerHTML={{ __html: slide.content }}></div>
            </div>,
          );
        }
        if (slide.type === 'Questions') {
          const questionContent = [];
          // Check if there before content for the question
          if (slide.questions.before) {
            questionContent.push(
              <div
                key={`${slideIndex}-before`}
                className="user-content"
                dangerouslySetInnerHTML={{ __html: slide.questions.before }}
              ></div>,
            );
          }

          // Get the selected answer
          const selectedAnswers = savedAnswers[slideIndex] || {};

          // Get the questions
          questionContent.push(
            <Questions
              key={`${slideIndex}-question`}
              questions={slide.questions.questions}
              selectedAnswers={selectedAnswers}
              onSaveProgress={data => this.saveProgress(slideIndex, data)}
            />,
          );

          // Check if there is after content
          if (slide.questions.after) {
            questionContent.push(
              <div key={`${slideIndex}-afer`} className="user-content" dangerouslySetInnerHTML={{ __html: slide.questions.after }}></div>,
            );
          }

          // Put everything into one slide
          slides.push(
            <div key={slideIndex} className="slide">
              {questionContent}

              <div className="question-bottom-padding"></div>
            </div>,
          );
        }
      });
    }

    return slides;
  }

  render() {
    const { user, page, posts } = this.props;

    const tabData = findTabDataForPage(posts, page);
    let slides = this.getSlides(page, user);
    slides = slides.concat(this.getNewSlides(page, user));

    return (
      <div className="pt-3 pb-3 container modules agp-wrapper">
        <div className="row">
          <div className="col-12 col-md-4 col-lg-3 text-center">
            <img src="/images/ADC_Hypo-Hyper_big_white.png" width="200" alt="" />
          </div>
          <div className="col-12 col-md-8 col-lg-9" dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
        </div>
        <div className="row">
          <div className="col-12">
            <div className="tab-wrapper">{tabData && <Tabs tabData={tabData} />}</div>

            <div id="module">{slides && <Slides slides={slides} />}</div>

            {(page.acf.disclaimerList || page.acf.references) && (
              <div id="accordion" class="disclaimers">
                <div className="card">
                  <div
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    className="card-header"
                    id="headingOne"
                  >
                    <div className="col-12">
                      <h2 className="mb-0">References &amp; Disclaimers</h2>
                    </div>
                  </div>
                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                      <div className="col-12">
                        {page.acf.disclaimerList ? (
                          <ul>
                            {page.acf.disclaimerList &&
                              page.acf.disclaimerList.map((item, i) =>
                                item.reference && item.disclaimer ? (
                                  <li key={i.toString()}>
                                    {item.reference} <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : item.reference ? (
                                  <li key={i.toString()}>{item.reference}</li>
                                ) : item.disclaimer ? (
                                  <li key={i.toString()}>
                                    <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : null,
                              )}
                          </ul>
                        ) : (
                          page.acf.references && <div dangerouslySetInnerHTML={{ __html: page.acf.references }}></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

// Map state to props for the component
const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts },
    user,
  } = state;

  return {
    user,
    posts,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPost,
    updateUserMeta,
  })(AGPPageTemplate),
);
