import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect, Provider } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';

import { loadPages } from '../actions/pages-actions';
import { loadVideoGalleries } from '../actions/video-gallery-actions';
import { loadCtaBlocks } from '../actions/cta-block-actions';
import { loadDisclaimers } from '../actions/disclaimer-actions';
import { loadRoles, loadCountries } from '../actions/taxonomies-actions';
import { loadUser } from '../actions/users-actions';
import { loadValidate } from '../actions/authentication-actions';

import PrivateRoute from '../components/PrivateRoute';

import Login from './Login';
import ResetPassword from './ResetPassword';
import Register from './Register';
import MyAccount from './MyAccount';
import ChangePassword from './ChangePassword';
import Footer from '../components/Footer';
import Header from './Header';
import Page from './Page';
import Post from './Post';
import Page404 from './Page404';
import SuperSteady from './SuperSteady';

class Root extends Component {
  static propTypes = {
    store: PropTypes.object.isRequired,
    loadPages: PropTypes.func.isRequired,
    loadVideoGalleries: PropTypes.func.isRequired,
    loadCtaBlocks: PropTypes.func.isRequired,
    loadDisclaimers: PropTypes.func.isRequired,
    loadRoles: PropTypes.func.isRequired,
    loadCountries: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
    loadValidate: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.loadPages();
    this.props.loadVideoGalleries();
    this.props.loadCtaBlocks();
    this.props.loadDisclaimers();
    this.props.loadRoles();
    this.props.loadCountries();
    const token = localStorage && localStorage.getItem('token') ? localStorage.getItem('token') : false;
    if (token) {
      this.props.loadValidate().then(response => {
        this.props.loadUser('me');
      });
    }
    this.props.history.listen(location => window.scrollTo(0, 0));
  }

  render() {
    const { store } = this.props;

    return (
      <Provider store={store}>
        <div className="container-fluid">
          <Header />
          <main>
            <Switch>
              <Route exact path="/sign-in" component={Login} />
              <Route exact path="/sign-up" component={Register} />
              <Route
                exact
                path="/gallery-test"
                render={props => {
                  props.match.params.slug = 'gallery-test';
                  return <Page {...props} />;
                }}
              />
              <Route exact path="/reset-password" component={ResetPassword} />

              <Route exact path="/public/:slug" component={Page} />

              <PrivateRoute exact path="/my-account" component={MyAccount} />
              <PrivateRoute exact path="/change-password" component={ChangePassword} />
              <PrivateRoute exact path="/:postTypeSlug(modules|casestudies)/:slug" component={Post} />
              <Route
                exact
                path="/"
                render={props => {
                  props.match.params.slug = 'home';
                  return <Page {...props} />;
                }}
              />
              <PrivateRoute exact path="/consumer/super-steady-and-friends" component={SuperSteady} />
              <PrivateRoute exact path="/:roleSlug(hcp|consumer)/*/:slug" component={Page} />
              <PrivateRoute exact path="/:roleSlug(hcp|consumer)/:slug" component={Page} />

              <Route
                exact
                path="/privacy-policy"
                render={props => {
                  props.match.params.slug = 'privacy-policy';
                  return <Page {...props} />;
                }}
              />
              <Route
                exact
                path="/cookie-policy"
                render={props => {
                  props.match.params.slug = 'cookie-policy';
                  return <Page {...props} />;
                }}
              />
              <Route
                exact
                path="/terms-of-use"
                render={props => {
                  props.match.params.slug = 'terms-of-use';
                  return <Page {...props} />;
                }}
              />
              <Route
                exact
                path="/Boots"
                render={props => {
                  props.match.params.slug = 'home';
                  return <Page {...props} />;
                }}
              />
              <Route component={Page404} />
            </Switch>
          </main>
          <Footer />
        </div>
      </Provider>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    loadPages,
    loadVideoGalleries,
    loadCtaBlocks,
    loadDisclaimers,
    loadRoles,
    loadCountries,
    loadUser,
    loadValidate,
  })(Root),
);
