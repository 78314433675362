import React from 'react';
import PropTypes from 'prop-types'

// Calculate the class names
function calculateClassNames(questions, selectedAnswers) {
  const completed = {};
  const selected = {};

  questions.forEach((question, questionIndex) => {
    // Calculate if its completed
    completed[questionIndex] =  '';
    const selectedQuestion = selectedAnswers[`question${questionIndex}`];
    if (selectedQuestion && selectedQuestion !== '') {
      completed[questionIndex] =  'complete';

      // Calculate if its selected
      selected[`${questionIndex}${selectedQuestion.selectedAnswer}`] = selectedQuestion.correct;
    }
  });

  return {
    completed,
    selected,
  };
}

const Questions = ({ questions, selectedAnswers, onSelectAnswer, onResetAnswers, isCorrectAnswerKey }) => {
  // Do nothing if there is no questions
  if (questions && questions.length === 0) {
    return false;
  }

  // Calculate completed
  const {
    completed,
    selected,
  } = calculateClassNames(questions, selectedAnswers);

  // Check if need to show restart button
  // Need always show the restart button, because when the question is inside a slide
  // the height will get calculated when is there
  const restartButtonClasses = ['btn', 'btn-orange', 'btn-restart'];
  if (Object.keys(selectedAnswers).length > 0) {
    restartButtonClasses.push('visible');
  } else {
    restartButtonClasses.push('invisible');
  }

  return (
    <div className="questions">
      <div className="example">
        <div className="container question-container">
          {questions.map((question, index)=>{
            return (
              <div key={index}>
                <div className="question-header">
                  <h2>{question.questionTitle}</h2>
                </div>
                <div className="question-content">
                  {question.questionImage && (
                    <div className="question-image">
                      <img src={question.questionImage} alt={question.questionTitle} />
                    </div>
                  )}

                  <ul className={completed[index]}>
                    {question.answers.map(function(answer, answerIndex){
                      var className = selected[index + "" + answerIndex];
                      if (completed[index] !== 'complete') {
                        return <li key={answerIndex} className={className} onClick={() => { onSelectAnswer(index, answerIndex) }}>{answer.answer}</li>
                      } else {
                        if (String(answer[isCorrectAnswerKey]) === "yes") {
                          return <li key={answerIndex} className="correct" >{answer.answer} <div className="more-answer-details">{question.correctAnswerDetails}</div></li>
                        } else {
                          return <li key={answerIndex} className={className} >{answer.answer}</li>
                        }
                      }
                    })}
                  </ul>
                </div>
              </div>
            );
            })}

            <div className="questions-actions">
              <button className={restartButtonClasses.join(' ')} onClick={() => { onResetAnswers() }}>Restart</button>
            </div>
          </div>
        </div>
    </div>
  );
}

Questions.propTypes = {
  questions: PropTypes.array.isRequired,
  selectAnswers: PropTypes.object,
  isCorrectAnswerKey: PropTypes.string,
  onSelectAnswer: PropTypes.func,
  onResetAnswers: PropTypes.func,
}

Questions.defaultProps = {
  isCorrectAnswerKey: 'isCorrectAnswer',
  onSelectAnswer: () => {},
  onResetAnswers: () => {},
}
export default Questions;
