import { CALL_API } from '../middleware/api';

export const PROGRESS_REQUEST = 'PROGRESS_REQUEST';
export const PROGRESS_SUCCESS = 'PROGRESS_SUCCESS';
export const PROGRESS_FAILURE = 'PROGRESS_FAILURE';

const fetchProgress = (userId, question) => ({
  userId,
  question,
  [CALL_API]: {
    types: [PROGRESS_REQUEST, PROGRESS_SUCCESS, PROGRESS_FAILURE],
    endpoint: 'abbott/v1/users/update-question',
    method: 'POST',
    body: {
      userId,
      question,
    },
  },
});


export const loadProgress = (userId, question) => (dispatch, getState) => dispatch(fetchProgress(userId, question));

const fetchMetaUpdate = (userId, key, value) => ({
  userId,
  key,
  value,
  [CALL_API]: {
    types: [PROGRESS_REQUEST, PROGRESS_SUCCESS, PROGRESS_FAILURE],
    endpoint: 'abbott/v1/users/add-meta',
    method: 'POST',
    body: {
      userId,
      key,
      value,
    },
  },
});

export const updateUserMeta = (userId, key, value) => dispatch => dispatch(fetchMetaUpdate(userId, key, value));
