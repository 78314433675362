import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPost } from '../../actions/posts-actions';
import { findTabDataForPage } from '../../utils';
// import PodcastGallery from "../PodcastGallery";
import Disclaimer from '../Disclaimers';
import { generateVideoCertificatePDF } from '../../helpers/pdf';
import { Cookies } from 'react-cookie-banner';

class VideoTrackingPageTemplate extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    tabs: PropTypes.object,
  };
  cookies = new Cookies();

  twentyFivePercentTriggered = false;
  fiftyPercentTriggered = false;
  ninetyPercentTriggered = false;
  hundredPercentTriggered = false;

  constructor(props) {
    super(props);

    this.progressVideo = this.progressVideo.bind(this);
    let triggered = this.cookies.get('videoTriggered');
    this.state = { triggered: triggered ? true : false };
  }

  componentWillMount() {
    const { page } = this.props;
    const tabBlock = page.acf.tabBlock;

    if (tabBlock) {
      this.props.loadPost('tab_content_block', tabBlock.postName);
    }
  }

  /**
   * Handles click events on items set in the dangerouslySetHTML container. Handles both diagram logic and links.
   * @param {object} e - The object the user has clicked
   */
  contentClickHandler(e) {
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i,
          el = this;
        do {
          i = matches.length;
          while (--i >= 0 && matches.item(i) !== el) {}
        } while (i < 0 && (el = el.parentElement));
        return el;
      };
    }
    e.preventDefault();
    var el = e.target;
    var parent = el.closest('li');
    var parent2 = el.closest('a');
    if (parent) {
      if (parent.classList.contains('tab-click-handler')) {
        var imageToShow = 'image-' + e.target.closest('a').id;
        var elements = document.getElementById(imageToShow).parentElement.querySelectorAll('.active');

        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }
        document.getElementById(imageToShow).classList.toggle('active');
      }
    }

    if (parent2) {
      var checkTab = parent2.closest('li');
      if (checkTab && checkTab.classList.contains('tab-click-handler')) {
      } else {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        /* eslint-disable-next-line */
        if (
          targetLink.hostname === 'stage-progress.freestylediabetes.co.uk' ||
          targetLink.hostname === 'progress.freestylediabetes.co.uk' ||
          targetLink.hostname === 'localhost'
        ) {
          /* remove the domain and leave the url path only */
          /* eslint-disable-next-line */
          var url = targetLink.href
            .replace('http://progress.freestylediabetes.co.uk', '')
            .replace('https://progress.freestylediabetes.co.uk', '')
            .replace('http://admin.freestylediabetes.co.uk', '')
            .replace('https://admin.freestylediabetes.co.uk', '')
            .replace('http://localhost:3000', '')
            .replace('https://localhost:3000', '');
          this.props.history.push(url);
        } else {
          window.open(targetLink, '_blank');
        }
      }
    }
  }

  tabbedArea() {
    const { page, posts } = this.props;

    // Find the tab dta for this page
    const tabData = findTabDataForPage(posts, page);

    if (tabData) {
      return (
        <div>
          <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
            {tabData.map(function (tab, tabIndex) {
              const id = 'body-tab' + tabIndex;
              const href = '#body' + tabIndex;
              const control = 'body' + tabIndex;
              return (
                <li key={tabIndex} className="nav-item tab-click-handler">
                  <a className="nav-link" id={id} data-toggle="tab" href={href} role="tab" aria-controls={control} aria-selected="true">
                    <img src={tab.icon} alt="" />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content" id="myTabContent">
            {tabData.map(function (tab, tabIndex) {
              const id = 'body-tab' + tabIndex;
              const href = 'body' + tabIndex;
              return (
                <div
                  key={tabIndex}
                  className="tab-pane fade"
                  id={href}
                  role="tabpanel"
                  aria-labelledby={id}
                  dangerouslySetInnerHTML={{ __html: tab.content }}
                ></div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  progressVideo(event) {
    const triggerDuration = (event.target.dataset.trigger / 100) * event.target.duration;
    let totalPlayed = 0;

    for (let i = 0; i < event.target.played.length; i++) {
      totalPlayed += event.target.played.end(i) - event.target.played.start(i);
    }

    if (totalPlayed >= triggerDuration && !this.state.triggered) {
      // GTM 'Video played' event
      console.log('Triggered: Video Tracking ' + event.target.dataset.trigger + '% Played');
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          category: 'Video Completion Triggered',
          action: event.target.dataset.trigger + '% Played',
          label: event.target.dataset.url,
        },
      });
      this.cookies.set('videoTriggered');

      this.setState({ triggered: true });
    }

    // Triger event also on 25%, 50%, 90% and 100% of video view
    let percent = '';
    const twentyFivePercentDuration = (25 / 100) * event.target.duration;
    const fiftyPercentDuration = (50 / 100) * event.target.duration;
    const ninetyPercentDuration = (90 / 100) * event.target.duration;
    const hundredPercentDuration = event.target.duration;
    if (totalPlayed >= twentyFivePercentDuration && !this.twentyFivePercentTriggered) {
      this.twentyFivePercentTriggered = true;
      percent = '25%';
    } else if (totalPlayed >= fiftyPercentDuration && !this.fiftyPercentTriggered) {
      this.fiftyPercentTriggered = true;
      percent = '50%';
    } else if (totalPlayed >= ninetyPercentDuration && !this.ninetyFivePercentTriggered) {
      this.ninetyFivePercentTriggered = true;
      percent = '90%';
    } else if (totalPlayed >= hundredPercentDuration && !this.hundredFivePercentTriggered) {
      this.hundredFivePercentTriggered = true;
      percent = '25%';
    }
    if (percent !== '') {
      console.log('Triggered: Video Played ' + percent);
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          category: 'Video Tracking',
          action: percent + ' Played',
          label: event.target.dataset.url,
        },
      });
    }
  }

  clickCertificate(visual, content, url, footer) {
    const { user } = this.props;

    generateVideoCertificatePDF(user.name, content, visual, footer);

    // GTM 'Video click CTA' event
    console.log('Triggered: Certificate Download');
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        category: 'Certificate Download',
        action: 'CTA Clicked',
        label: url,
      },
    });
  }

  render() {
    const { page, posts } = this.props;

    const youtubeVideosNumber = page.acf.youtubeVideosNumber ? parseInt(page.acf.youtubeVideosNumber) : 0;

    // Find the tab dta for this page
    const tabData = findTabDataForPage(posts, page);

    return (
      <div className="videoTracking-wrapper">
        <div className="pt-3 pb-3 container">
          <article className="single page">
            <div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
            {tabData && (
              <div onClick={this.contentClickHandler} className="full-width-tabs">
                <div className="pt-3 pb-3 container">
                  <div className="row">
                    <div className="col-4">
                      <div className="body-diagram">
                        <div className="diagram">
                          <img className="active" src={process.env.PUBLIC_URL + '/body.png'} alt="body" width="126" height="264" />
                          {tabData.map(function (tab, tabIndex) {
                            const imageKey = 'image-body-tab' + tabIndex;
                            return <img key={imageKey} id="brain" src={tab.mainImage} alt="" width="126" height="264" />;
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-8">{this.tabbedArea()}</div>
                  </div>
                </div>
              </div>
            )}
            <div className="aligncenter video">
              <video width="100%" data-trigger={page.acf.percent} data-url={page.link} controls onTimeUpdate={this.progressVideo}>
                <source src={page.acf.video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            {this.state.triggered && (
              <div>
                <div className="brochure">
                  <div className="intro" dangerouslySetInnerHTML={{ __html: page.acf.buttonIntro }}></div>

                  <div className="aligncenter button">
                    <div
                      className="btn btn-orange"
                      onClick={() => this.clickCertificate(page.acf.pdfVisual.url, page.acf.pdfContent, page.link, page.acf.pdfFooter)}
                    >
                      {page.acf.buttonLabel}
                    </div>
                  </div>
                </div>
                <div className="youtubeVideos row">
                  {[...Array(youtubeVideosNumber)].map((_, i) => (
                    <div
                      key={i}
                      className={`col-md-${Math.floor(12 / youtubeVideosNumber)} ${
                        i === 0 && youtubeVideosNumber === 5 ? 'offset-md-1' : ''
                      }`}
                    >
                      <iframe
                        width="100%"
                        src={page.acf[`video${i + 1}`]}
                        title="Video Player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                      <div>{page.acf[`videoTitle${i + 1}`]}</div>
                    </div>
                  ))}
                </div>
              </div>
            )}
            <div>
              {page.acf.yellowBannerText && (
                <div className="yellow-banner row">
                  <h5>{page.acf.yellowBannerText}</h5>
                  <a href={page.acf.yellowBannerLink} className="btn btn-orange">
                    {page.acf.yellowBannerButtonText}
                  </a>
                </div>
              )}
              {page.acf.footerImage && page.acf.footerImageMobile && (
                <div className="image-banner row ">
                  <a href={page.acf.footerImageLink}>
                    <img alt="Create your account" src={page.acf.footerImage.url} className="d-none d-md-block" />
                    <img alt="Create your account" src={page.acf.footerImageMobile.url} className="d-block d-md-none" />
                  </a>
                </div>
              )}
              {page.acf.whiteBannerText && (
                <div className="white-banner row">
                  <h5>{page.acf.whiteBannerText}</h5>
                  <a href={page.acf.whiteBannerLink} className="btn btn-orange">
                    {page.acf.whiteBannerButtonText}
                  </a>
                </div>
              )}
            </div>

            <div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: page.acf.bottomContent }}></div>
            {(page.acf.disclaimerList || page.acf.references) && (
              <div id="accordion" className="disclaimers">
                <div className="card">
                  <div
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    className="card-header"
                    id="headingOne"
                  >
                    <div className="col-12">
                      <h2 className="mb-0">References &amp; Disclaimers</h2>
                    </div>
                  </div>
                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                      <div className="col-12">
                        {page.acf.disclaimerList ? (
                          <ul>
                            {page.acf.disclaimerList &&
                              page.acf.disclaimerList.map((item, i) =>
                                item.reference && item.disclaimer ? (
                                  <li key={i.toString()}>
                                    {item.reference} <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : item.reference ? (
                                  <li key={i.toString()}>{item.reference}</li>
                                ) : item.disclaimer ? (
                                  <li key={i.toString()}>
                                    <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : null,
                              )}
                          </ul>
                        ) : (
                          page.acf.references && <div dangerouslySetInnerHTML={{ __html: page.acf.references }}></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div></div>
          </article>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts },
    user,
  } = state;

  return {
    posts,
    user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPost,
  })(VideoTrackingPageTemplate),
);
