import { CALL_API, Schemas } from '../middleware/api';

export const PAGE_REQUEST = 'PAGE_REQUEST';
export const PAGE_SUCCESS = 'PAGE_SUCCESS';
export const PAGE_FAILURE = 'PAGE_FAILURE';

const fetchPage = slug => ({
  [CALL_API]: {
    types: [PAGE_REQUEST, PAGE_SUCCESS, PAGE_FAILURE],
    endpoint: 'wp/v2/pages',
    schema: Schemas.PAGE_ARRAY,
    query: { slug },
  },
});

export const loadPage = slug => (dispatch, getState) => {
  const page = getState().entities.pages[slug];
  if (page) {
    return null;
  }

  return dispatch(fetchPage(slug));
};

export const PAGES_REQUEST = 'PAGES_REQUEST';
export const PAGES_SUCCESS = 'PAGES_SUCCESS';
export const PAGES_FAILURE = 'PAGES_FAILURE';

const fetchPages = () => ({
  [CALL_API]: {
    types: [PAGES_REQUEST, PAGES_SUCCESS, PAGES_FAILURE],
    endpoint: 'wp/v2/pages',
    schema: Schemas.PAGE_ARRAY,
  },
});

export const loadPages = () => (dispatch, getState) => dispatch(fetchPages());
