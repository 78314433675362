import {
  Checkbox, Option,

  Radio, RadioGroup, Select, Text,

  TextArea
} from 'informed';
import React from 'react';
import { formatBritishPostcode, isHcpAllowEmail } from '../../utils';

// Rules for validate the serial numbers
// Type and regex for it
const meterTypeValidationRules = {
  'freestyle-insulinx': /[a-zA-z]{4}[0-9]{3}[a-zA-z]{1}[0-9]{4}/,
  'freestyle-optium-neo': /[a-zA-z]{4}[0-9]{3}[a-zA-z]{1}[0-9]{5}/,
  'freestyle-libre': /[a-zA-z]{4}[0-9]{3}[-]{1}[a-zA-z]{1}[0-9]{4}/,
  'freestyle-lite': /[a-zA-z]{4}[0-9]{3}[a-zA-z]{1}[0-9]{4}/,
  'freestyle-freedom-lite': /[a-zA-z]{4}[0-9]{3}[a-zA-z]{1}[0-9]{4}/,
  'freestyle-optium': /[a-zA-z]{3}[0-9]{3}[a-zA-z]{1}[0-9]{1}[a-zA-z]{2}[0-9]{1}/,
};
//const freedomlitePattern = /[a-zA-z]{3}[0-9]{3}[a-zA-z]{1}[0-9]{1}[a-zA-z]{2}[0-9]{1}/;

// Validation
const validateRequired = value => {
  return value ? null : 'Sorry but this field is required';
}

const validatePostcode = (value, values) => {
  if (values.country === 'ireland') {
    return null
  }

  return value ? null : 'Please enter a valid postcode.';
}

// Validation
const validateEmail = value => {
  return value.indexOf('@') === -1 ? 'Sorry this is not a valid email' : null;
}

const validateRequiredEmail = value => {
  return validateRequired(value) || validateEmail(value)
}

const validateHCPEmail = value => {
  let validated = validateEmail(value) || validateRequired(value)  
  
  if (validated === null) {
    validated = isHcpAllowEmail(value)
  } else {
    return validated;
  } 

  return validated ? null : 'Please use either your NHS, HSE or institution email address';
}

// Only validate if there is rule for it, otherwise it doesn't do the validation
const validateSerialNumber = (value, values) => {
  if (values.meterType) {
    const regex = meterTypeValidationRules[values.meterType];
    if (regex){
      return value.match(regex) ? null : 'Sorry please enter a correct serial number for the selected meter type';
    }
  }
}


// The Form
const FormContent = ({ controller, formState, formApi }) => {
  // Get the type from the initial vaues
  let type = null;
  const initialValues = controller.config.initialValues;
  if (initialValues && initialValues.type) {
    type = initialValues.type;
  }

  const { values: { country }, errors, submits } = formState;

  const counties = ["Co. Carlow","Co. Cavan","Co. Clare","Co. Cork","Co. Donegal","Co. Dublin North","Co. Dublin South","Co. Dublin West","Co. Galway","Co. Kerry","Co. Kildare","Co. Kilkenny","Co. Laois","Co. Leitrim","Co. Limerick","Co. Longford","Co. Louth","Co. Mayo","Co. Meath","Co. Monaghan","Co. Offaly","Co. Roscommon","Co. Sligo","Co. Tipperary","Co. Waterford","Co. Westmeath","Co. Wexford","Co. Wicklow", "Dublin 1", "Dublin 2", "Dublin 3", "Dublin 4", "Dublin 5", "Dublin 6", "Dublin 6W", "Dublin 7", "Dublin 8", "Dublin 9", "Dublin 10", "Dublin 11", "Dublin 12", "Dublin 13", "Dublin 14", "Dublin 15", "Dublin 16", "Dublin 17", "Dublin 18", "Dublin 19" ,"Dublin 20","Dublin 22","Dublin 24"];

    let countiesList = counties.map(function(county, i){
      return <option key={`county-${i}`} value={county}>{county}</option>;
    })

  // Render the form
  return (
    <div className={submits > 0 ? 'was-validated' : ''}>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountTitle">* Title</label>
        <div className="col-sm-8">
          <Select field="title" className="form-control custom-select" id="myAccountTitle" validate={validateRequired} required>
            <Option value="" disabled>Select Your title</Option>
            <Option value="master">Master</Option>
            <Option value="mr">Mr</Option>
            <Option value="miss">Miss</Option>
            <Option value="mrs">Mrs</Option>
            <Option value="ms">Ms</Option>
          </Select>

          {errors.title && (
          <div className="invalid-feedback">
            Please select a title.
          </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountFirstName">* First Name</label>
        <div className="col-sm-8">
          <Text type="text" className="form-control" id="myAccountFirstName" placeholder="First Name" field="firstName" autoComplete="given-name" validate={validateRequired} required />
          {errors.firstName && (
            <div className="invalid-feedback">
              Please enter your first name.
            </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountLastName">* Last Name</label>
        <div className="col-sm-8">
          <Text type="text" className="form-control" id="myAccountLastName" placeholder="Last Name" field="lastName" autoComplete="family-name" validate={validateRequired} required />

          {errors.lastName && (
          <div className="invalid-feedback">
            Please enter your last name.
          </div>
          )}
        </div>
      </div>
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountEmail">* Email</label>
        <div className="col-sm-8">
          {type === 'consumer' && (
              <Text type="email" className={`form-control ${ errors.email ? 'is-invalid' : '' }`} id="myAccountEmail" placeholder="Email" field="email" autoComplete="email" validate={validateRequiredEmail} required />
          )}
          {type === 'hcp' && (
              <Text type="email" className={`form-control ${ errors.email ? 'is-invalid' : '' }`} id="myAccountEmail" placeholder="Email" field="email" autoComplete="email" validate={validateHCPEmail} required />
          )}
          {errors.email && (
          <div className="invalid-feedback">
            {errors.email}
          </div>
          )}
        </div>
      </div>
      {country === 'ireland' && (
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="myAccountCounty">* County</label>
          <div className="col-sm-8">
            <Select field="county" className="form-control custom-select" id="myAccountCounty" validate={validateRequired} required>
              <Option value="" disabled>Please Select County</Option>
              {countiesList}
            </Select>
            {errors.county && (
              <div className="invalid-feedback">
                Please enter your county.
              </div>
            )}
          </div>
        </div>
      )}
      {country === 'uk' && (
        <div className="form-group row">
          <label className="col-sm-4 col-form-label" htmlFor="myAccountPostcode">* Postcode</label>
          <div className="col-sm-8">
          <Text
            type="text"
            maxLength="8"
            onBlur={
            (e) => formApi.setValue('postcode', formatBritishPostcode(e.target.value))
            }
            className={`form-control ${ errors.postcode && country !== 'ireland' ? 'is-invalid' : '' }`}
            id="myAccountPostcode"
            placeholder="Postcode"
            field="postcode"
            autoComplete="postal-code"
            validate={validatePostcode}
            required={ country !== 'ireland' ? true : false }
          />

            {errors.postcode && country !== 'ireland' && (
            <div className="invalid-feedback">
              { errors.postcode }
            </div>
            )}
          </div>
        </div>
      )}
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountCountry">* Country</label>
        <div className="col-sm-8">
          <Select field="country" className="form-control custom-select" id="myAccountCountry" validate={validateRequired} required>
            <Option value="" disabled>Select Your country</Option>
            <Option value="uk">UK</Option>
            <Option value="ireland">Ireland</Option>
          </Select>

          {errors.country && (
          <div className="invalid-feedback">
            Please select a country.
          </div>
          )}
        </div>
      </div>
      {type && (
        <div>
          {type === 'consumer' ? (
            <div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" htmlFor="myAccountMeterType">Meter Type</label>
                <div className="col-sm-8">
                  <Select className="form-control custom-select" id="myAccountMeterType" field="meterType">
                    <Option value="" disabled>Select Your Meter</Option>
                    <Option value="freestyle-freedom-lite">FreeStyle Freedom Lite</Option>
                    <Option value="freestyle-libre">FreeStyle Libre</Option>
                    <Option value="freestyle-lite">FreeStyle Lite</Option>
                    <Option value="freestyle-insulinx">FreeStyle InsuLinx</Option>
                    <Option value="freestyle-optium">FreeStyle Optium</Option>
                    <Option value="freestyle-optium-neo">FreeStyle Optium Neo</Option>
                  </Select>
                </div>
              </div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" htmlFor="myAccountMeterSerialNumber">Meter Serial Number</label>
                <div className="col-sm-8">
                  <Text className="form-control" type="text" id="myAccountMeterSerialNumber" field="meterSerialNumber" validate={validateSerialNumber}/>
                  <i>Please include dashes where relevant</i>
                  {errors.meterSerialNumber && (
                  <div className="invalid-feedback">
                    {errors.meterSerialNumber}
                  </div>
                  )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">What is your main source of professional help to manage your diabetes?</div>
                <div className="col-sm-8">
                  <RadioGroup field="sourceOfHelp">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio value="gp" className="custom-control-input" type="radio" id="myAccountSourceOfHelpGP" />
                    <label className="custom-control-label" htmlFor="myAccountSourceOfHelpGP">
                      GP
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio value="hospital" className="custom-control-input" type="radio" id="myAccountSourceOfHelpHospital" />
                    <label className="custom-control-label" htmlFor="myAccountSourceOfHelpHospital">
                      Hospital/Specialist clinic (and GP)
                    </label>
                  </div>
                  </RadioGroup>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-sm-4">Do you adjust your insulin dose at mealtimes?</div>
                <div className="col-sm-8">
                  <RadioGroup field="adjustInsulinAtMealTime">
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio value="yes" className="custom-control-input" type="radio" id="myAccountInsulinMealTimeYes" />
                    <label className="custom-control-label" htmlFor="myAccountInsulinMealTimeYes">
                      Yes
                    </label>
                  </div>
                  <div className="custom-control custom-radio custom-control-inline">
                    <Radio value="no" className="custom-control-input" type="radio" id="myAccountInsulinMealTimeNo" />
                    <label className="custom-control-label" htmlFor="myAccountInsulinMealTimeNo">
                      No
                    </label>
                  </div>
                  </RadioGroup>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="form-group row">
                <label className="col-sm-4 col-form-label" htmlFor="myAccountJobRole">* Job Role</label>
                <div className="col-sm-8">
                  <Select className="form-control" id="myAccountJobRole" field="jobRole" validate={validateRequired} required>
                    <Option value="" disabled>Select Your Job Role</Option>
                    <Option value="diabetes-specialist-nurse">Diabetes specialist nurse</Option>
                    <Option value="paediatric-nurse">Paediatric nurse</Option>
                    <Option value="pharmacist">Pharmacist</Option>                   
                    <Option value="practice-nurse">Practice nurse</Option>
                    <Option value="gp">GP</Option>
                    <Option value="consultant">Consultant</Option>
                    <Option value="other">Other healthcare professional</Option>
                  </Select>

                  {errors.jobRole && (
                    <div className="invalid-feedback">
                      Please select a job role.
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="form-group row">
        <label className="col-sm-4 col-form-label" htmlFor="myAccountAddress">Address</label>
        <div className="col-sm-8">
          <TextArea className="form-control" id="myAccountAddress" field="address" rows="5"></TextArea>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-4">Educational Content</div>
        <div className="col-sm-8">
          <div className="custom-control custom-checkbox custom-control-inline">
            <Checkbox field="marketingEducationContentEmail" className="custom-control-input" type="checkbox" id="myAccountEducationalContentEmail" />
            <label className="custom-control-label" htmlFor="myAccountEducationalContentEmail">
              Email
            </label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <Checkbox field="marketingEducationContentApp" className="custom-control-input" type="checkbox" id="myAccountEducationalContentApp" />
            <label className="custom-control-label" htmlFor="myAccountEducationalContentApp">
              App
            </label>
          </div>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-4">Feedback Surveys</div>
        <div className="col-sm-8">
          <div className="custom-control custom-checkbox custom-control-inline">
            <Checkbox field="marketingFeedbackSurveysEmail" className="custom-control-input" type="checkbox" id="myAccountFeedbackSurveysEmail" />
            <label className="custom-control-label" htmlFor="myAccountFeedbackSurveysEmail">
              Email
            </label>
          </div>
          <div className="custom-control custom-checkbox custom-control-inline">
            <Checkbox field="marketingFeedbackSurveysApp" className="custom-control-input" type="checkbox" id="myAccountFeedbackSurveysApp" />
            <label className="custom-control-label" htmlFor="myAccountFeedbackSurveysApp">
              App
            </label>
          </div>
        </div>
      </div>
      {type && type === 'consumer' && (
        <div className="form-group row">
          <div className="col-sm-4">Offers and Promotions</div>
          <div className="col-sm-8">
            <div className="custom-control custom-checkbox custom-control-inline">
              <Checkbox field="marketingOffersAndPromotionsEmail" className="custom-control-input" type="checkbox" id="myAccountOffersAndPromotionsEmail" />
              <label className="custom-control-label" htmlFor="myAccountOffersAndPromotionsEmail">
                Email
              </label>
            </div>
            <div className="custom-control custom-checkbox custom-control-inline">
              <Checkbox field="marketingOffersAndPromotionsApp" className="custom-control-input" type="checkbox" id="myAccountOffersAndPromotionsApp" />
              <label className="custom-control-label" htmlFor="myAccountOffersAndPromotionsApp">
                App
              </label>
            </div>
          </div>
        </div>
      )}
      {/*
      <div className="form-group row">
        <div className="col-sm-4">Push Notifications</div>
        <div className="col-sm-8">
          <div className="custom-control custom-checkbox custom-control-inline">
            <Checkbox field="pushNotification" className="custom-control-input" type="checkbox" id="myAccountNotifications"/>
            <label className="custom-control-label" htmlFor="myAccountNotifications">
            </label>
          </div>
        </div>
      </div>
      */}
      <div className="form-group row">
        <div className="col-sm-8 offset-sm-4">
          <button type="submit" className="btn btn-orange">
            Submit
          </button>
        </div>
      </div>
      <div className="form-group row">
        <div className="col-sm-8 offset-sm-4">
          <small className="form-text text-muted">
            (*) Required fields are indicated with an asterisk.
          </small>
        </div>
      </div>
    </div>
  )
};

export default FormContent;
