import find from 'lodash/find';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPage } from '../actions/pages-actions';
import Loader from '../components/Loader';
import AcademyPageTemplate from '../components/pages/AcademyPageTemplate';
import AGPPageTemplate from '../components/pages/AGPPageTemplate';
import BannerRow from '../components/pages/BannerRow';
import CaseStudiesPageTemplate from '../components/pages/CaseStudiesPageTemplate';
import DefaultPageTemplate from '../components/pages/DefaultPageTemplate';
import HCPRegisterPageTemplate from '../components/pages/HCPRegisterPageTemplate';
import HomePageTemplate from '../components/pages/HomePageTemplate';
import MeetingsInABoxTemplate from '../components/pages/MeetingsInABoxTemplate';
import OrderingPageTemplate from '../components/pages/OrderingPageTemplate';
import PodcastGalleryPageTemplate from '../components/pages/PodcastGalleryPageTemplate';
import ReviewsPageTemplate from '../components/pages/ReviewsPageTemplate';
import VideoGalleryPageTemplate from '../components/pages/VideoGalleryPageTemplate';
import VideoTrackingPageTemplate from '../components/pages/VideoTrackingPageTemplate';

class PagePage extends Component {
  static propTypes = {
    roleSlug: PropTypes.string,
    slug: PropTypes.string,
    page: PropTypes.object,
    user: PropTypes.object,
    loadPage: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.props.loadPage(this.props.slug);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.slug !== this.props.slug) {
      this.props.loadPage(nextProps.slug);
    }
  }

  renderPage(page, user) {
    if (page.template === 'home') {
      return <HomePageTemplate page={page} user={user} />;
    } else if (page.template === 'ordering') {
      return <OrderingPageTemplate page={page} />;
    } else if (page.template === 'reviews') {
      return <ReviewsPageTemplate page={page} />;
    } else if (page.template === 'academy') {
      return <AcademyPageTemplate page={page} user={user} />;
    } else if (page.template === 'casestudies') {
      return <CaseStudiesPageTemplate page={page} user={user} />;
    } else if (page.template === 'agp') {
      return <AGPPageTemplate page={page} user={user} />;
    } else if (page.template === 'meetingsinabox') {
      return <MeetingsInABoxTemplate page={page} user={user} />;
    } else if (page.template === 'videogallery') {
      return <VideoGalleryPageTemplate page={page} />;
    } else if (page.template === 'podcastgallery') {
      return <PodcastGalleryPageTemplate page={page} />;
    } else if (page.template === 'videotracking') {
      return <VideoTrackingPageTemplate page={page} />;
    } else if (page.template === 'hcpregister') {
      return <HCPRegisterPageTemplate page={page} />;
    }
    else {
      return <DefaultPageTemplate page={page} />;
    }
  }

  render() {
    const { page, user } = this.props;
    return (
      <div>
        {page ? (
          <div className={`page ${page.template}`}>
            {page.acf && page.acf.banner && page.acf.banner && <BannerRow banner={page.acf.banner} />}
            <div>{this.renderPage(page, user)}</div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  //const roleSlug = (ownProps.match.params.roleSlug || '').toLowerCase()
  const slug = (ownProps.match.params.slug || '').toLowerCase();

  const {
    entities: { roles, pages, countries },
    user,
  } = state;

  const type = user && user.meta && user.meta.type ? user.meta.type : '';

  let currentRole = find(roles, role => {
    return role.slug === type;
  });

  // Recover the public page role so we can compare
  const publicRole = find(roles, role => {
    return role.slug === 'public';
  });

  const page = find(pages, page => {
    // Check if the page has the public role
    const isPublic = publicRole ? page.roles.indexOf(publicRole.id) > -1 : false;
    if (!isPublic) {
      // If not public check if current role matches
      if (page.slug === slug) {
        if (currentRole) {
          return page.roles.indexOf(currentRole.id) > -1;
        } else {
          return page.roles.length === 0;
        }
      } else {
        return false;
      }
    } else {
      // Page is mark as public so allow render
      if (page.slug === slug) {
        return true;
      }
    }
  });

  // Filter out by user country
  if (user && user.meta && user.meta.country) {
    if (page && Array.isArray(page.countries) && page.countries.length > 0) {
      let found = false;
      for (const country of page.countries) {
        if (countries[country].slug === user.meta.country) {
          found = true;
        }
      }
      if (!found) return false;
    }
  }

  return {
    slug,
    page,
    user,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPage,
  })(PagePage),
);
