import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// import is from 'is_js';
import { doOfflineStatusRequest } from '../actions/offline-actions';
import GitVersion from '../git-version.json';

class Footer extends Component {
  componentWillReceiveProps(nextProps) {
    // Send request to save last_offline time
    if (this.props.user.id && nextProps.online === false) {
      this.props.doOfflineStatusRequest(this.props.user.id);
    }
  }

  render() {
    const gitVersion = GitVersion;

    return (
      <footer className="row">
        <div className="container">
          <p>
            FreeStyle, Libre, and related brand marks are marks of Abbott. Other trademarks are the property of their respective owners. No
            use of any Abbott trademark, trade name, or trade dress in this site may be made without the prior written authorisation of
            Abbott Laboratories, except to identify the product or services of the company.
            <br />
            This website and the information contained herein is intended for use by residents of the UK and Ireland. The product images are
            for illustrative purposes only.
            <br />
            <br />
            ©️ {new Date().getFullYear()} Abbott Laboratories Limited. All rights reserved.
            <br />
            <br />
            Registered Number: 329102 England. Registered Office: Abbott House, Vanwall Business Park, Vanwall Road, Maidenhead, Berkshire,
            SL6 4XE
          </p>
        </div>
        <div className="container footer-links">
          <Link to="/privacy-policy">privacy policy</Link>
          <Link to="/cookie-policy">cookie policy</Link>
          <Link to="/terms-of-use">terms of use</Link>
          <a target="_blank" rel="noopener noreferrer" href="https://www.diabetescare.abbott/support/manuals/uk.html">
            smartphone compatibility guide
          </a>
          <div id="teconsent"></div>
        </div>
        <div className="container footer-version">
          <span>version: </span>
          <span>
            {gitVersion.version}.{gitVersion.hash}{' '}
          </span>
        </div>
      </footer>
    );
  }
}

const mapStateToProps = state => {
  if (state.offline && state.user) {
    const {
      user,
      offline: { online },
    } = state;

    return {
      user,
      online,
    };
  }
};

export default connect(mapStateToProps, { doOfflineStatusRequest })(Footer);
