import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import CircularProgressbar from 'react-circular-progressbar';

const Module = ({ module }) => {
  const { title, link, color, icon, style, progressPercentage } = module;
  // Handle there is no progress percentage
  let percentageText = '';
  let percentage = 0;
  if (typeof progressPercentage !== 'undefined') {
    percentageText = `${progressPercentage.toFixed()}%`;
  }

  return (
    <div className="col-6 col-md-3">
      <Link to={link}>
        <div className="progress-area">
          <div className="progress-bar-container">
            <div style={{ position: 'absolute' }}>
              <CircularProgressbar
                percentage={percentage}
                counterClockwise
                strokeWidth={2}
                styles={{ path: { stroke: color }, trail: { stroke: color, opacity: 0.5 } }}
              />
            </div>

            <div className="progress-bar-content">
              <div style={{ color: color }} height={120}>
                <img src={icon} alt="" className={style} />
                <h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
                {percentageText}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};

const Listing = ({ modules }) => {
  // Do nothing if there is no modules
  if (modules.length === 0) {
    return false;
  }

  return (
    <React.Fragment>
      {modules.map((module, index) => (
        <Module key={index} module={module} />
      ))}
    </React.Fragment>
  );
};

Listing.propTypes = {
  modules: PropTypes.array.isRequired,
};

export default Listing;
