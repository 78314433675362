import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { loadReview } from '../../actions/reviews-actions';

import Loader from '../Loader';

import map from 'lodash/map';
import range from 'lodash/range';

class ReviewsPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      success: false,
      rating: 0,
    };
    this.renderForm = this.renderForm.bind(this);
    this.renderSuccess = this.renderSuccess.bind(this);
    this.renderError = this.renderError.bind(this);

    this.getStarSrc = this.getStarSrc.bind(this);
    this.handleRatingInput = this.handleRatingInput.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false, success: false });
  }

  onSubmit(event) {
    event.preventDefault();

    this.setState({ error: false, loading: true, success: false });

    this.props
      .loadReview({
        meter: this.refs.meter.value,
        duration: this.refs.duration.value,
        rating: this.state.rating,
        review: this.refs.review.value,
        name: this.refs.name.value,
        age: this.refs.age.value,
        permissionToContact: this.refs.permissionToContact.checked,
        permissionToUse: this.refs.permissionToUse.checked,
      })
      .then(response => {
        if (response.error) {
          this.setState({ error: response.error, loading: false, success: false });
        } else {
          this.setState({ error: false, loading: false, success: true });
        }
      });
  }

  handleRatingInput(event) {
    this.setState({ rating: event.target.dataset.rating });
  }

  getStarSrc(rating) {
    return rating > this.state.rating
      ? process.env.PUBLIC_URL + '/icons/icon-star-black.png'
      : process.env.PUBLIC_URL + '/icons/icon-star-yellow.png';
  }

  renderForm() {
    const meters = ['FreeStyle Optium Neo', 'FreeStyle Libre', 'FreeStyle InsuLinx'];
    const years = range(1, 11);

    const meterOptions = map(meters, meter => {
      return (
        <option key={meter} value={meter}>
          {meter}
        </option>
      );
    });

    const yearOptions = map(years, year => {
      if (year === 10) {
        year = '10+';
      }
      return (
        <option key={year} value={year}>
          {year} Year(s)
        </option>
      );
    });

    return (
      <form className="col-12 col-md-10 offset-md-1" onSubmit={this.onSubmit}>
        <div className="form-group row">
          <label htmlFor="inputYourMeter" className="col-sm-5 col-form-label">
            *Your Meter
          </label>
          <div className="col-sm-7">
            <select className="form-control" id="inputYourMeter" ref="meter" required>
              {meterOptions}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputLengthOfOwnerShip" className="col-sm-5 col-form-label">
            *Length of ownership
          </label>
          <div className="col-sm-7">
            <select className="form-control" id="inputLengthOfOwnerShip" ref="duration" required>
              {yearOptions}
            </select>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputRating" className="col-sm-5 col-form-label">
            Rating
          </label>
          <div className="col-sm-7">
            <img src={this.getStarSrc(1)} data-rating="1" alt="1 star" onClick={this.handleRatingInput} />
            <img src={this.getStarSrc(2)} data-rating="2" alt="2 star" onClick={this.handleRatingInput} />
            <img src={this.getStarSrc(3)} data-rating="3" alt="3 star" onClick={this.handleRatingInput} />
            <img src={this.getStarSrc(4)} data-rating="4" alt="4 star" onClick={this.handleRatingInput} />
            <img src={this.getStarSrc(5)} data-rating="5" alt="5 star" onClick={this.handleRatingInput} />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputReview" className="col-sm-5 col-form-label">
            *Review
          </label>
          <div className="col-sm-7">
            <textarea className="form-control" id="inputReview" placeholder="Review" rows="10" ref="review" required></textarea>
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputDisplayName" className="col-sm-5 col-form-label">
            Display Name
          </label>
          <div className="col-sm-7">
            <input type="text" className="form-control" id="inputDisplayName" placeholder="" ref="name" />
          </div>
        </div>
        <div className="form-group row">
          <label htmlFor="inputAge" className="col-sm-5 col-form-label">
            Age
          </label>
          <div className="col-sm-7">
            <input type="text" className="form-control" id="inputAge" placeholder="" ref="age" />
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-7 offset-sm-5">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="inputPermissionToContact" ref="permissionToContact" />
              <label className="form-check-label" htmlFor="inputPermissionToContact">
                Please tick here if you’re happy for Abbott Diabetes Care to contact you regarding your review
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-7 offset-sm-5">
            <div className="form-check">
              <input className="form-check-input" type="checkbox" id="inputPermissionToUse" ref="permissionToUse" />
              <label className="form-check-label" htmlFor="inputPermissionToUse">
                I give permission for FreeStyle to use my review in marketing/promotional material
              </label>
            </div>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-7 offset-sm-5">
            <button type="submit" className="btn btn-primary">
              Submit
              {this.state.loading && <Loader />}
            </button>
          </div>
        </div>

        {this.renderSuccess()}

        {this.renderError()}
      </form>
    );
  }

  renderSuccess() {
    const { success } = this.state;

    if (success) {
      return (
        <div className="alert alert-success alert-dismissible fade show" role="alert">
          <div>Success - Thanks for your review</div>
          <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  renderError() {
    const { error } = this.state;

    if (error) {
      return (
        <div className="alert alert-danger alert-dismissible fade show" role="alert">
          <div>{error}</div>
          <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      );
    }
  }

  render() {
    const { page } = this.props;

    return (
      <div className="pt-3 pb-3 container">
        <div className="row intro">
          <div className="col-12" dangerouslySetInnerHTML={{ __html: page.acf.introduction }}></div>
        </div>
        <div className="row helpline">
          <div className="col-12">
            <h2>{page.acf.contact.title}</h2>
          </div>
          <div className="col-12 col-md-3">
            <img
              className="img-fluid"
              src={page.acf.contact.image.url}
              alt={page.acf.contact.image.alt}
              height={page.acf.contact.image.height}
              width={page.acf.contact.image.width}
            />
          </div>
          <div className="col-12 col-md-9" dangerouslySetInnerHTML={{ __html: page.acf.contact.content }}></div>
        </div>
        <div className="row bg-secondary review-form">
          <div>
            <img
              className="img-fluid"
              src={page.acf.form.image.url}
              alt={page.acf.form.image.alt}
              height={page.acf.form.image.height}
              width={page.acf.form.image.width}
            />
            <h2>{page.acf.form.title}</h2>
          </div>
          {this.renderForm()}
          <div className="col-12 col-md-10 offset-md-1 references" dangerouslySetInnerHTML={{ __html: page.acf.form.disclaimer }}></div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {};
};

export default withRouter(
  connect(mapStateToProps, {
    loadReview,
  })(ReviewsPageTemplate),
);
