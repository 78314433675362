import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  updateUser,
  HCP_META,
  CONSUMER_META,
  doGdprRequest,
} from '../actions/users-actions';

import { validatePostcode } from '../services/postcode';

import BannerRow from '../components/pages/BannerRow';
import Loader from '../components/Loader';
import FormContent from '../components/Forms/Account';
import GDPR from '../components/GDPR';
import {
  Form,
} from 'informed';

import { pick } from 'lodash/object';

class MyAccountPage extends Component {
  constructor(props) {
    super(props);

    // Default state
    this.state = {
      error: false,
      postcodeError: false,
      loading: false,
      success: false,
    }

    // Create ref to the form
    this.formContainerRef = React.createRef();

    // Bind this to the function
    this.renderForm = this.renderForm.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.onSubmitFailure= this.onSubmitFailure.bind(this);
    this.setFormApi = this.setFormApi.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false, success: false })
  }

  onSubmit(data) {
    const { user } = this.props;
    const userType = user.meta.type;

    // Set the state for loading
    this.setState({
      error: false,
      loading: true,
      success: false
    });

    // Set the payload
    let payload = {
      first_name: data.firstName,
      last_name: data.lastName,
      email: data.email,
    }
    if (userType === 'hcp') {
      payload['meta'] = pick(data, HCP_META);
    } else {
      payload['meta'] = pick(data, CONSUMER_META);
    }

    // Add back the type
    payload.meta.type = userType;

    // validate postcode if UK
    if (data.country === 'uk') {
      validatePostcode(data.postcode)
        .then(response => {
          if (!response.result) {
            this.setState({
              postcodeError: true,
              loading: false
            })
            // use informed formApi to pass down error...
            this.formApi.setError('postcode', 'Please enter a valid postcode.')
          } else {
            // Save data
            this.props.updateUser(payload)
            .then((response) => {
              if (response.error) {
                this.setState({
                  error: response.error,
                  postcodeError: false,
                  loading: false,
                  success: false
                })
              } else {
                this.setState({
                  error: false,
                  postcodeError: false,
                  loading: false,
                  success: true
                })
              }
            })
          }
        })
    } else {
      // Country is ireland, so no postcode validation
      this.props.updateUser(payload)
      .then((response) => {
        if (response.error) {
          this.setState({
            error: response.error,
            postcodeError: false,
            loading: false,
            success: false
          })
        } else {
          this.setState({
            error: false,
            postcodeError: false,
            loading: false,
            success: true
          })
        }
      })
    }
  }

  setFormApi (formApi) {
    this.formApi = formApi;
  }

  onSubmitFailure(errors) {
    // Scroll to the element
    if (this.formContainerRef.current) {
      this.formContainerRef.current.scrollIntoView();
    }
  }

  renderForm() {
    const { user } = this.props
    const { meta } = user

    // If there is no type inside meta, then show loading
    if (!meta || !meta.type) {
      return (
        <Loader />
      );
    }

    // Construct the inital values
    const data = Object.assign({
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
    }, meta);

    return (
      <div id="my-account-form-container" ref={this.formContainerRef} className="inner-block bg-light">
        <Form
          className="needs-validation bg-faded"
          component={FormContent}
          onSubmit={this.onSubmit}
          getApi={this.setFormApi}
          initialValues={data}
          onSubmitFailure={this.onSubmitFailure}
          noValidate
        />

        {this.state.loading && (<Loader />)}
      <div className="form-group row">
        <div className="col-sm-8 offset-sm-4">
          {this.state.error && (
            <div className="alert alert-danger alert-dismissible fade show" role="alert">
              <div dangerouslySetInnerHTML={{__html: this.state.error}}></div>
              <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          {this.state.success && (
            <div className="alert alert-success alert-dismissible fade show" role="alert">
              <p>The data is successfully saved!</p>
              <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}
        </div>
      </div>

    </div>
    )
  }

  render() {
    const {
      doGdprRequest,
      user,
    } = this.props;

    const banner = {
      content: "<h1>MY ACCOUNT</h1><p>Add to or edit your account details</p>",
      changePasswordButton: true,
    }

    return (
      <div className="page my-account">
        <BannerRow banner={banner} />
        <div className="container">
          <div className="row">
            <div className="outer-block col-12 bg-dark">
              {this.renderForm()}
            </div>
          </div>
          <div className="row">
            <div className="outer-block col-12 bg-dark">
              <div className="inner-block bg-light">
                <GDPR onConfirmRequest={doGdprRequest} data={user.id}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

// Proptypes
MyAccountPage.propTypes = {
  user: PropTypes.object.isRequired,
  updateUser: PropTypes.func.isRequired
}

// Get state into the component
const mapStateToProps = (state, ownProps) => {
  const {
    user
  } = state

  return {
    user
  }
}

export default withRouter(connect(mapStateToProps, {
  updateUser,
  doGdprRequest,
})(MyAccountPage))
