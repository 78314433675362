import { CALL_API, Schemas } from '../middleware/api';

export const POST_REQUEST = 'POST_REQUEST';
export const POST_SUCCESS = 'POST_SUCCESS';
export const POST_FAILURE = 'POST_FAILURE';

const fetchPost = (type, slug) => ({
  [CALL_API]: {
    types: [POST_REQUEST, POST_SUCCESS, POST_FAILURE],
    endpoint: `wp/v2/${type}`,
    schema: Schemas.POST_ARRAY,
    query: { slug },
  },
});

export const loadPost = (type = 'posts', slug = '') => (dispatch, getState) => {
  const post = getState().entities.posts[slug];
  if (post) {
    return null;
  }

  return dispatch(fetchPost(type, slug));
};

export const POSTS_REQUEST = 'POSTS_REQUEST';
export const POSTS_SUCCESS = 'POSTS_SUCCESS';
export const POSTS_FAILURE = 'POSTS_FAILURE';

const fetchPosts = type => ({
  [CALL_API]: {
    types: [POSTS_REQUEST, POSTS_SUCCESS, POSTS_FAILURE],
    endpoint: `wp/v2/${type}`,
    schema: Schemas.POST_ARRAY,
  },
});

export const loadPosts = (type = 'posts') => (dispatch, getState) => dispatch(fetchPosts(type));
