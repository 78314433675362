import { CALL_API, Schemas } from '../middleware/api';

/**
 * Fetch all the taxonomies
 */

export const TAXONOMIES_REQUEST = 'TAXONOMIES_REQUEST';
export const TAXONOMIES_SUCCESS = 'TAXONOMIES_SUCCESS';
export const TAXONOMIES_FAILURE = 'TAXONOMIES_FAILURE';

const fetchTaxonomies = () => ({
  [CALL_API]: {
    types: [TAXONOMIES_REQUEST, TAXONOMIES_SUCCESS, TAXONOMIES_FAILURE],
    endpoint: 'wp/v2/taxonomies',
    schema: Schemas.TAXONOMY_ARRAY,
  },
});

export const loadTaxonomies = () => (dispatch, getState) => {
  const taxonomies = getState().entities.taxonomies;
  if (Object.keys(taxonomies).length > 0) {
    return null;
  }

  return dispatch(fetchTaxonomies());
};

/**
 * Fetch a single taxonomy
 */

export const TAXONOMY_REQUEST = 'TAXONOMY_REQUEST';
export const TAXONOMY_SUCCESS = 'TAXONOMY_SUCCESS';
export const TAXONOMY_FAILURE = 'TAXONOMY_FAILURE';

const fetchTaxonomy = taxonomyId => ({
  [CALL_API]: {
    types: [TAXONOMY_REQUEST, TAXONOMY_SUCCESS, TAXONOMY_FAILURE],
    endpoint: `wp/v2/taxonomies/${taxonomyId}`,
    schema: Schemas.TAXONOMY,
  },
});

export const loadTaxonomy = taxonomyId => (dispatch, getState) => {
  const taxonomy = getState().entities.taxonomies[taxonomyId];
  if (taxonomy) {
    return null;
  }

  return dispatch(fetchTaxonomy(taxonomyId));
};

/**
 * Fetch all the roles
 */

export const ROLES_REQUEST = 'ROLES_REQUEST';
export const ROLES_SUCCESS = 'ROLES_SUCCESS';
export const ROLES_FAILURE = 'ROLES_FAILURE';

const fetchRoles = () => ({
  [CALL_API]: {
    types: [ROLES_REQUEST, ROLES_SUCCESS, ROLES_FAILURE],
    endpoint: 'wp/v2/roles',
    schema: Schemas.ROLE_ARRAY,
  },
});

export const loadRoles = () => (dispatch, getState) => {
  const roles = getState().entities.roles;
  if (Object.keys(roles).length > 0) {
    return null;
  }

  return dispatch(fetchRoles());
};

/**
 * Fetch a single role
 */

export const ROLE_REQUEST = 'ROLE_REQUEST';
export const ROLE_SUCCESS = 'ROLE_SUCCESS';
export const ROLE_FAILURE = 'ROLE_FAILURE';

const fetchRole = roleId => ({
  [CALL_API]: {
    types: [ROLE_REQUEST, ROLE_SUCCESS, ROLE_FAILURE],
    endpoint: `wp/v2/roles/${roleId}`,
    schema: Schemas.ROLE,
  },
});

export const loadRole = roleId => (dispatch, getState) => {
  const role = getState().entities.roles[roleId];
  if (role) {
    return null;
  }

  return dispatch(fetchRole(roleId));
};

/**
 * Fetch all the product types
 */

export const PRODUCT_TYPES_REQUEST = 'PRODUCT_TYPES_REQUEST';
export const PRODUCT_TYPES_SUCCESS = 'PRODUCT_TYPES_SUCCESS';
export const PRODUCT_TYPES_FAILURE = 'PRODUCT_TYPES_FAILURE';

const fetchProductTypes = () => ({
  [CALL_API]: {
    types: [PRODUCT_TYPES_REQUEST, PRODUCT_TYPES_SUCCESS, PRODUCT_TYPES_FAILURE],
    endpoint: 'wp/v2/product_types',
    schema: Schemas.PRODUCT_TYPE_ARRAY,
  },
});

export const loadProductTypes = () => (dispatch, getState) => {
  const products = getState().entities.productTypes;
  if (Object.keys(products).length > 0) {
    return null;
  }

  return dispatch(fetchProductTypes());
};

/**
 * Fetch a single product type
 */

export const PRODUCT_TYPE_REQUEST = 'PRODUCT_TYPE_REQUEST';
export const PRODUCT_TYPE_SUCCESS = 'PRODUCT_TYPE_SUCCESS';
export const PRODUCT_TYPE_FAILURE = 'PRODUCT_TYPE_FAILURE';

const fetchProductType = productTypeId => ({
  [CALL_API]: {
    types: [PRODUCT_TYPE_REQUEST, PRODUCT_TYPE_SUCCESS, PRODUCT_TYPE_FAILURE],
    endpoint: `wp/v2/product_types/${productTypeId}`,
    schema: Schemas.PRODUCT_TYPE,
  },
});

export const loadProductType = productTypeId => (dispatch, getState) => {
  const productType = getState().entities.productTypes[productTypeId];
  if (productType) {
    return null;
  }

  return dispatch(fetchProductType(productTypeId));
};

/**
 * Fetch all the countries
 */

export const COUNTRIES_REQUEST = 'COUNTRIES_REQUEST';
export const COUNTRIES_SUCCESS = 'COUNTRIES_SUCCESS';
export const COUNTRIES_FAILURE = 'COUNTRIES_FAILURE';

const fetchCountries = () => ({
  [CALL_API]: {
    types: [COUNTRIES_REQUEST, COUNTRIES_SUCCESS, COUNTRIES_FAILURE],
    endpoint: 'wp/v2/countries',
    schema: Schemas.COUNTRY_ARRAY,
  },
});

export const loadCountries = () => (dispatch, getState) => {
  const countries = getState().entities.countries;
  if (Object.keys(countries).length > 0) {
    return null;
  }

  return dispatch(fetchCountries());
};

/**
 * Fetch a single country
 */

export const COUNTRY_REQUEST = 'COUNTRY_REQUEST';
export const COUNTRY_SUCCESS = 'COUNTRY_SUCCESS';
export const COUNTRY_FAILURE = 'COUNTRY_FAILURE';

const fetchCountry = countryId => ({
  [CALL_API]: {
    types: [COUNTRY_REQUEST, COUNTRY_SUCCESS, COUNTRY_FAILURE],
    endpoint: `wp/v2/countries/${countryId}`,
    schema: Schemas.COUNTRY,
  },
});

export const loadCountry = countryId => (dispatch, getState) => {
  const country = getState().entities.countries[countryId];
  if (country) {
    return null;
  }

  return dispatch(fetchCountry(countryId));
};
