import { CALL_API, Schemas } from '../middleware/api';

/**
 * Fetch all cta blocks
 */

export const DISCLAIMERS_REQUEST = 'DISCLAIMERS_REQUEST';
export const DISCLAIMERS_SUCCESS = 'DISCLAIMERS_SUCCESS';
export const DISCLAIMERS_FAILURE = 'DISCLAIMERS_FAILURE';

const fetchDisclaimers = () => ({
  [CALL_API]: {
    types: [DISCLAIMERS_REQUEST, DISCLAIMERS_SUCCESS, DISCLAIMERS_FAILURE],
    endpoint: 'acf/v3/disclaimers',
    schema: Schemas.DISCLAIMER_ARRAY,
  },
});

export const loadDisclaimers = () => (dispatch, getState) => {
  const disclaimers = getState().entities.disclaimers;
  if (Object.keys(disclaimers).length > 0) {
    return null;
  }

  return dispatch(fetchDisclaimers());
};
