import PropTypes from 'prop-types';
import React, { Component } from 'react';
import TagManager from 'react-gtm-module';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPost } from '../../actions/posts-actions';
import { findTabDataForPage } from '../../utils';
// import PodcastGallery from "../PodcastGallery";
import Disclaimer from '../Disclaimers';

class PodcastGalleryPageTemplate extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    tabs: PropTypes.object,
  };

  constructor(props) {
    super(props);

    this.contentClickHandler = this.contentClickHandler.bind(this);

    this.state = { playIndex: -1 };

    this.progressAudio = this.progressAudio.bind(this);
    this.playAudio = this.playAudio.bind(this);
    this.clickSpotify = this.clickSpotify.bind(this);
    this.clickApple = this.clickApple.bind(this);
    this.audioRefs = [];
  }

  componentWillMount() {
    const { page } = this.props;
    const tabBlock = page.acf.tabBlock;
    if (tabBlock) {
      this.props.loadPost('tab_content_block', tabBlock.postName);
    }
    //this.props.loadPosts('tab_content_block')
  }

  /**
   * Handles click events on items set in the dangerouslySetHTML container. Handles both diagram logic and links.
   * @param {object} e - The object the user has clicked
   */
  contentClickHandler(e) {
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i,
          el = this;
        do {
          i = matches.length;
          while (--i >= 0 && matches.item(i) !== el) {}
        } while (i < 0 && (el = el.parentElement));
        return el;
      };
    }
    e.preventDefault();
    var el = e.target;
    var parent = el.closest('li');
    var parent2 = el.closest('a');
    if (parent) {
      if (parent.classList.contains('tab-click-handler')) {
        var imageToShow = 'image-' + e.target.closest('a').id;
        var elements = document.getElementById(imageToShow).parentElement.querySelectorAll('.active');

        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }
        document.getElementById(imageToShow).classList.toggle('active');
      }
    }

    if (parent2) {
      var checkTab = parent2.closest('li');
      if (checkTab && checkTab.classList.contains('tab-click-handler')) {
      } else {
        const targetLink = e.target.closest('a');
        if (!targetLink) return;
        /* eslint-disable-next-line */
        if (
          targetLink.hostname === 'stage-progress.freestylediabetes.co.uk' ||
          targetLink.hostname === 'progress.freestylediabetes.co.uk' ||
          targetLink.hostname === 'localhost'
        ) {
          /* remove the domain and leave the url path only */
          /* eslint-disable-next-line */
          var url = targetLink.href
            .replace('http://progress.freestylediabetes.co.uk', '')
            .replace('https://progress.freestylediabetes.co.uk', '')
            .replace('http://admin.freestylediabetes.co.uk', '')
            .replace('https://admin.freestylediabetes.co.uk', '')
            .replace('http://localhost:3000', '')
            .replace('https://localhost:3000', '');
          this.props.history.push(url);
        } else {
          window.open(targetLink, '_blank');
        }
      }
    }
  }

  tabbedArea() {
    const { page, posts } = this.props;

    // Find the tab dta for this page
    const tabData = findTabDataForPage(posts, page);

    if (tabData) {
      return (
        <div>
          <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
            {tabData.map(function (tab, tabIndex) {
              const id = 'body-tab' + tabIndex;
              const href = '#body' + tabIndex;
              const control = 'body' + tabIndex;
              return (
                <li key={tabIndex} className="nav-item tab-click-handler">
                  <a className="nav-link" id={id} data-toggle="tab" href={href} role="tab" aria-controls={control} aria-selected="true">
                    <img src={tab.icon} alt="" />
                  </a>
                </li>
              );
            })}
          </ul>
          <div className="tab-content" id="myTabContent">
            {tabData.map(function (tab, tabIndex) {
              const id = 'body-tab' + tabIndex;
              const href = 'body' + tabIndex;
              return (
                <div
                  key={tabIndex}
                  className="tab-pane fade"
                  id={href}
                  role="tabpanel"
                  aria-labelledby={id}
                  dangerouslySetInnerHTML={{ __html: tab.content }}
                ></div>
              );
            })}
          </div>
        </div>
      );
    }
  }

  progressAudio(event) {
    let totalPlayed = 0;
    for (let i = 0; i < event.target.played.length; i++) {
      totalPlayed += event.target.played.end(i) - event.target.played.start(i);
    }

    if (!event.target.play30sec) {
      if (totalPlayed >= 30) {
        event.target.play30sec = true;

        // GTM 'Podcast 30s played' event
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            category: 'Podcast',
            action: '30secs',
            label: 'Episode ' + (this.state.playIndex + 1),
          },
        });
      }
    }

    if (!event.target.playCompleted) {
      if (totalPlayed >= event.target.duration - 10) {
        event.target.playCompleted = true;

        // GTM 'Podcast completed' event
        TagManager.dataLayer({
          dataLayer: {
            event: 'custom_event',
            category: 'Podcast',
            action: 'Completed',
            label: 'Episode ' + (this.state.playIndex + 1),
          },
        });
      }
    }
  }

  playAudio(i) {
    if (this.state.playIndex !== -1 && this.state.playIndex !== i) {
      this.audioRefs[this.state.playIndex].pause();
    }
    this.setState({ playIndex: i });

    if (this.audioRefs[i].played.length === 0) {
      this.audioRefs[i].play30sec = false;
      this.audioRefs[i].playCompleted = false;
    }

    // GTM 'Podcast clicked' event
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        category: 'Podcast',
        action: 'Click',
        label: 'Episode ' + (i + 1),
      },
    });
  }

  clickSpotify(i) {
    // GTM 'Podcast spotify click' event
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        category: 'Podcast',
        action: 'Spotify',
        label: 'Episode ' + (i + 1),
      },
    });
  }

  clickApple(i) {
    // GTM 'Podcast apple click' event
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_event',
        category: 'Podcast',
        action: 'Apple',
        label: 'Episode ' + (i + 1),
      },
    });
  }

  render() {
    const { page, posts } = this.props;

    // Find the tab dta for this page
    const tabData = findTabDataForPage(posts, page);

    return (
      <div>
        <div className="pt-3 pb-3 container">
          <article className="single page">
            <div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
            {tabData && (
              <div onClick={this.contentClickHandler} className="full-width-tabs">
                <div className="pt-3 pb-3 container">
                  <div className="row">
                    <div className="col-4">
                      <div className="body-diagram">
                        <div className="diagram">
                          <img className="active" src={process.env.PUBLIC_URL + '/body.png'} alt="body" width="126" height="264" />
                          {tabData.map(function (tab, tabIndex) {
                            const imageKey = 'image-body-tab' + tabIndex;
                            return <img key={imageKey} id="brain" src={tab.mainImage} alt="" width="126" height="264" />;
                          })}
                        </div>
                      </div>
                    </div>
                    <div className="col-8">{this.tabbedArea()}</div>
                  </div>
                </div>
              </div>
            )}

            {page.acf.title && <h2>{page.acf.title}</h2>}

            {page.acf.description && <div className="description" dangerouslySetInnerHTML={{ __html: page.acf.description }}></div>}

            <ul className="podcast-gallery">
              {page.acf.podcast &&
                page.acf.podcast.map((item, i) => (
                  <li key={i.toString()}>
                    <div className="text">
                      <h3>Episode {i + 1}</h3>
                      <h4>{item.title}</h4>
                      <h5>{item.date}</h5>
                      <div className="description" dangerouslySetInnerHTML={{ __html: item.description }}></div>
                    </div>
                    <div className="players">
                      <audio
                        ref={ref => (this.audioRefs[i] = ref)}
                        onPlay={() => this.playAudio(i)}
                        onTimeUpdate={this.progressAudio}
                        controls
                      >
                        <source src={item.audio} type="audio/mpeg" />
                        Your browser does not support the audio element.
                      </audio>

                      <div className="external">
                        {item.spotify && (
                          <a
                            href={item.spotify}
                            onClick={() => this.clickSpotify(i)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="spotify"
                          >
                            <img src="/images/spotify.png" alt="spotify" />
                          </a>
                        )}

                        {item.apple && (
                          <a
                            href={item.apple}
                            onClick={() => this.clickApple(i)}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="apple"
                          >
                            <img src="/images/apple_podcast.png" alt="apple podcast" />
                          </a>
                        )}
                      </div>
                    </div>
                  </li>
                ))}
            </ul>

            <div onClick={this.contentClickHandler} dangerouslySetInnerHTML={{ __html: page.acf.bottomContent }}></div>
            {(page.acf.disclaimerList || page.acf.references) && (
              <div id="accordion" class="disclaimers">
                <div className="card">
                  <div
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                    className="card-header"
                    id="headingOne"
                  >
                    <div className="col-12">
                      <h2 className="mb-0">References &amp; Disclaimers</h2>
                    </div>
                  </div>
                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                      <div className="col-12">
                        {page.acf.disclaimerList ? (
                          <ul>
                            {page.acf.disclaimerList &&
                              page.acf.disclaimerList.map((item, i) =>
                                item.reference && item.disclaimer ? (
                                  <li key={i.toString()}>
                                    {item.reference} <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : item.reference ? (
                                  <li key={i.toString()}>{item.reference}</li>
                                ) : item.disclaimer ? (
                                  <li key={i.toString()}>
                                    <Disclaimer id={item.disclaimer} />
                                  </li>
                                ) : null,
                              )}
                          </ul>
                        ) : (
                          page.acf.references && <div dangerouslySetInnerHTML={{ __html: page.acf.references }}></div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div></div>
          </article>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts },
  } = state;

  return {
    posts,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPost,
  })(PodcastGalleryPageTemplate),
);
