import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPage } from '../actions/pages-actions';
import Loader from '../components/Loader';

class SuperSteady extends Component {
  static propTypes = {
    authenticated: PropTypes.boolean,
    user: PropTypes.object,
  };

  render() {
    const { authenticated } = this.props;
    return (
      <div>
        {authenticated ? (
          <div className="page super-steady">
            <div className="pt-3 pb-3 container">
              <div className="row">
                <div className="col-12 gutters">
                  <h1 className="mt-5">
                    <span className="g-italic">Join the educational adventures of</span>
                    SUPER STEADY AND FRIENDS
                  </h1>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12 col-lg-9 gutters">
                  <p>
                    Meet the three friends; <b>Jumpy Grasshopper</b>, <b>Super Steady</b> and <b>Go Low Monster</b>. <br />
                    They love to go on adventures together - but in order to keep safe and enjoy themselves they must first learn how to
                    keep within their target glucose range.
                  </p>
                </div>
              </div>
            </div>
            <div className="character-bar">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-xs-12 col-md-8">
                    <img
                      className="img-responsive"
                      src="/images/steady/characters-bar.gif"
                      alt="Jumpy Grasshopper, Super Steady and Go Low Monster"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-12 gutters pb-5">
                  <p className="pb-4 mt-3">
                    This page provides fun and educational resources to help keep your child familiar with managing their diabetes.{' '}
                    <b>The resources are all free</b> and updated on a regular basis, so check back frequently for the next adventure.
                  </p>
                </div>
              </div>
            </div>
            <div className="pb-3 pb-md-5 gutters bg-yellow">
              <div className="container">
                <div className="row col-match">
                  <div className="col-md-12">
                    <h2>E-BOOKS</h2>
                    <p className="mar-t-10 bran-bold">
                      Discover these fun, educational, and colourful e-books designed as an aid for healthcare professionals and carers to
                      help your discussions with children who have diabetes.
                    </p>
                  </div>
                </div>
                <div className="row col-match">
                  <div className="col-md-5">
                    <h3>Jumpy's First School Trip</h3>
                    <p className="mar-t-10">
                      It's the day of the school trip and <b>Jumpy Grasshopper</b> should be excited for their first trip to Faraway Castle
                      - 
                      <b>Jumpy </b>is a bit nervous. Managing diabetes on your own can be scary. See the ups and downs of <b>Jumpy's </b>
                      day out and learn what <b>Mummy Grasshopper</b> should pack for Jumpy's next school trip.
                    </p>
                    <a
                      className="orange-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://progress.freestylediabetes.co.uk/super-steady-and-friends/school-trip/index.html"
                    >
                      READ NOW
                    </a>
                  </div>
                  <div className="col-md-7 pb-md-5">
                    <img
                      className="img-responsive mt-4"
                      src="/images/steady/jumpy-school-trip-book-animated.gif"
                      alt="Jumpy's First School Trip Book Cover"
                    />
                  </div>
                  <div className="col-md-7 pb-md-5">
                    <img
                      className="img-responsive mt-4"
                      src="/images/steady/party-island-book-animated.png"
                      alt="Jumpy's First School Trip Book Cover"
                    />
                  </div>
                  <div className="col-md-5">
                    <h3>The Search For The Party Island</h3>
                    <p className="mar-t-10">
                      <b>Princess Butterfly</b> has shared her knowledge of a secret party island with her three friends:{' '}
                      <b>Jumpy Grasshopper</b>, <b>Super Steady</b> and <b>Go Low Monster</b>. Their mission is to get to the party safely.
                      Read the story today.
                    </p>
                    <a
                      className="orange-btn"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://progress.freestylediabetes.co.uk/super-steady-and-friends/party-island/index.html"
                    >
                      READ NOW
                    </a>
                  </div>
                </div>
                <div className="row col">
                  <h3 style={{ margin: 'auto' }}>MORE BOOKS COMING UP SOON</h3>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-md-10 gutters">
                  <h2>ACTIVITIES</h2>
                  <p className="mt-2 mb-5 bran-bold">
                    The following activities are great fun for children and help them develop important skills whilst learning more about
                    managing diabetes.
                  </p>
                </div>
              </div>
            </div>

            <div className="bg-yellow pt-3 pb-3">
              <div className="container">
                <h2 className="mt-2">THE SEARCH FOR THE PARTY ISLAND</h2>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12 gutters">
                  <h3 className="mt-3 mb-3">
                    <img className="icon-print img-responsive pr-3" src="/icons/icon-print.svg" alt="Print" />
                    YOU WILL NEED A PRINTER TO PRINT THE ACTIVITY SHEETS
                  </h3>
                </div>
              </div>
            </div>

            <div className="container-fluid gutters">
              <div className="row">
                <div className="col-sm-12 col-md-6 bg-blue-light">
                  <div className="fluid-left">
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 center-small-screen order-lg-last">
                        <img
                          className="img-responsive mt-4"
                          src="/images/steady/spot-the-difference-sheet.jpg"
                          alt="Spot the difference activity sheet"
                        />
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <h4>SPOT THE DIFFERENCE</h4>
                        <p>
                          The pictures in this worksheet look the same right? Not quite, there are six minor differences between the two
                          images. Can you find them?
                        </p>
                        <a
                          className="orange-btn mb-4"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://progress.freestylediabetes.co.uk/super-steady-and-friends/ADC-20012v1.0+-+Spot+The+Difference+-+A4.pdf"
                        >
                          DOWNLOAD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 bg-blue-mid pb-5">
                  <div className="fluid-right">
                    <div className="row">
                      <div className="col-sm-12 col-lg-6 center-small-screen order-lg-last">
                        <img className="img-responsive mt-4" src="/images/steady/word-search-sheet.jpg" alt="Word Search activity sheet" />
                      </div>
                      <div className="col-sm-12 col-lg-6">
                        <h4>WORD SEARCH</h4>
                        <p>
                          Word Searches have many benefits such as improving spelling, concentration, enhancing visual awareness, and more
                          importantly, they're a fun way to learn the key terms in diabetes.
                        </p>
                        <a
                          className="orange-btn mb-4"
                          target="_blank"
                          rel="noopener noreferrer"
                          href="https://progress.freestylediabetes.co.uk/super-steady-and-friends/ADC-19985v1.0+-+Wordsearch.pdf"
                        >
                          DOWNLOAD
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-blue-dark gutters">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-lg-5">
                    <h4>COLOURING SHEETS</h4>
                    <p>Make the Party Island more colourful with the printable colouring sheet.</p>
                    <a
                      className="orange-btn mar-b-20"
                      target="_blank"
                      rel="noopener noreferrer"
                      href="https://progress.freestylediabetes.co.uk/super-steady-and-friends/ADC-20013v.0+-+paeds+Colouring.pdf"
                    >
                      DOWNLOAD
                    </a>
                  </div>
                  <div className="col-sm-12 col-lg-7">
                    <img
                      className="img-responsive stick-bot"
                      src="/images/steady/colouring-activity-sheet.png"
                      alt="Colouring activity sheet"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <Loader />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state;

  const type = user && user.meta && user.meta.type ? user.meta.type : '';
  if (type === 'consumer') {
    return {
      authenticated: true,
      user,
    };
  } else {
    return {
      authenticated: false,
      user,
    };
  }
};

export default withRouter(
  connect(mapStateToProps, {
    loadPage,
  })(SuperSteady),
);
