import { API_ROOT } from '../config';

export const OFFLINE_STATUS_REQUEST = 'OFFLINE_STATUS_REQUEST';
export const OFFLINE_STATUS_SUCCESS = 'OFFLINE_STATUS_SUCCESS';
export const OFFLINE_STATUS_FAILURE = 'OFFLINE_STATUS_FAILURE';

const OfflineStatusRequest = userId => ({
  type: OFFLINE_STATUS_REQUEST,
  meta: {
    offline: {
      effect: { url: `${API_ROOT}abbott/v1/users/offline/${userId}`, method: 'POST' },
      commit: { type: OFFLINE_STATUS_SUCCESS },
      rollback: { type: OFFLINE_STATUS_FAILURE },
    },
  },
});

export const doOfflineStatusRequest = userId => dispatch => dispatch(OfflineStatusRequest(userId));
