import merge from 'lodash/merge';
import { combineReducers } from 'redux';

import * as ActionTypes from '../actions';

// Reducers
import preferences from './preferences';
import authentication from './authentication';
import user from './user';

// Updates an entity cache in response to any action with response.entities.
const entities = (
  state = {
    categories: {},
    media: {},
    menus: {},
    pages: {},
    posts: {},
    productTypes: {},
    tags: {},
    roles: {},
    taxonomies: {},
    types: {},
    users: {},
    videoGalleries: {},
    ctaBlocks: {},
    disclaimers: {},
    countries: {},
  },
  action,
) => {
  if (action.response && action.response.entities) {
    return merge({}, state, action.response.entities);
  }
  return state;
};

// Updates global settings in response to any options/settings/root requests
const settings = (state = {}, action) => {
  const { type } = action;

  switch (type) {
    case ActionTypes.OPTIONS_SUCCESS:
    case ActionTypes.SETTINGS_SUCCESS:
    case ActionTypes.ROOT_SUCCESS:
      return merge({}, state, action.response);
    default:
  }

  return state;
};

// Updates error message to notify about the failed fetches.
const errorMessage = (state = null, action) => {
  const { type, error } = action;

  if (type === ActionTypes.RESET_ERROR_MESSAGE) {
    return null;
  }
  if (error) {
    return error;
  }

  return state;
};

// Heart beat
// Global heart beat state
// We can use this to show a global banner tell user when its offline
const heartbeatInitialState = true;
const heartbeat = (state = heartbeatInitialState, action) => {
  const { type, payload } = action;

  if (type === ActionTypes.HEARTBEAT) {
    return payload;
  }
  return state;
};

const rootReducer = combineReducers({
  heartbeat,
  entities,
  authentication,
  settings,
  user,
  preferences, // This is to store use preferences
  errorMessage,
});

export default rootReducer;
