import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPosts } from '../../actions/posts-actions';
import Listing from '../Listing/';
import { stripUrlHostname } from '../../utils';
import { updatePreference } from '../../actions/preferences-actions';
import Disclaimer from '../Disclaimers';

// Page type
const TYPE = 'casestudies';
// const CASE_STUDY_ADMIN_EMAIL = 'shona.edwards@abbott.com';
// const SUBJECT = 'A new case study submission';
// const BODY_MESSAGE = 'Hello There,';
// const MAIL_TO = `mailto:${CASE_STUDY_ADMIN_EMAIL}?subject=${SUBJECT}&body=${BODY_MESSAGE}`;

class CaseStudiesPageTemplate extends Component {
  constructor(props) {
    super(props);
    this.handleSelectionChange = this.handleSelectionChange.bind(this);
  }

  componentWillMount() {
    this.props.loadPosts('caseStudies');
  }

  handleSelectionChange(caseStudiesSelections) {
    // Save it in the redux
    this.props.updatePreference({
      caseStudiesSelections,
    });
  }

  render() {
    const { page, filters, caseStudies, limit, offset, selectedFilters } = this.props;

    return (
      <div className="pt-3 pb-3 container caseStudies">
        <div className="row">
          <div className="col-12" dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
        </div>
        <div className="row">
          <div className="col-12 col-md-12">
            <div className="outer-box">
              <Listing
                items={caseStudies}
                filters={filters}
                limit={limit}
                offset={offset}
                selectedFilters={selectedFilters}
                onSelectionChange={this.handleSelectionChange}
              />
            </div>
          </div>

          {/* <div className="col-12 col-md-12">
            <div className="outer-box">
              <h3>New case study?</h3>
              <p>Submit a new case study to help expand the collection and share learning with peers. It’s easy to do, just follow the requirements below and submit.</p>
              <ul>
                <li>Summary</li>
                <li>Patient information</li>
                <li>Treatment parameters</li>
                <li>Lab data</li>
                <li>Steps carried out during the study</li>
                <li>Technics/programs used to obtain/analyse the data</li>
              </ul>
              <a className="btn btn-orange" href={MAIL_TO} >Submit a new case study</a>
            </div>
          </div> */}
        </div>

        {(page.acf.disclaimerList || page.acf.references) && (
          <div id="accordion" class="disclaimers">
            <div className="card">
              <div
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                className="card-header"
                id="headingOne"
              >
                <div className="col-12">
                  <h2 className="mb-0">References &amp; Disclaimers</h2>
                </div>
              </div>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                  <div className="col-12">
                    {page.acf.disclaimerList ? (
                      <ul>
                        {page.acf.disclaimerList &&
                          page.acf.disclaimerList.map((item, i) =>
                            item.reference && item.disclaimer ? (
                              <li key={i.toString()}>
                                {item.reference} <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : item.reference ? (
                              <li key={i.toString()}>{item.reference}</li>
                            ) : item.disclaimer ? (
                              <li key={i.toString()}>
                                <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : null,
                          )}
                      </ul>
                    ) : (
                      page.acf.references && <div dangerouslySetInnerHTML={{ __html: page.acf.references }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// Map state to props for the component
const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts },
    preferences: { caseStudiesSelections },
  } = state;

  // Assign the selected filter
  const { limit, offset, selectedFilters } = caseStudiesSelections;

  // Only get the caseStudy post type
  let caseStudies = [];
  if (Object.keys(posts).length) {
    caseStudies = Object.values(posts).filter(caseStudy => caseStudy.type === TYPE);

    // Transform it for listing component - making new array rather mutate the existing one
    caseStudies = caseStudies.reduce((acc, caseStudy) => {
      acc.push({
        id: caseStudy.id,
        title: caseStudy.title.rendered,
        content: caseStudy.content.rendered,
        filterFields: caseStudy.acf,
        link: stripUrlHostname(caseStudy.link),
      });

      return acc;
    }, []);
  }

  // Working out the filters
  let filters = [];

  if (caseStudies.length) {
    const filterSet = caseStudies.reduce((acc, caseStudy) => {
      // Check if there is filter fields key
      // Ony add if the value is boolean value for toggle filter for now
      if (caseStudy.filterFields) {
        Object.keys(caseStudy.filterFields).forEach(key => {
          if (typeof caseStudy.filterFields[key] === 'boolean' && key !== 'columns' && key !== 'disclaimerList' && key !== 'slides' && key !== 'thumbnail') {
            acc.add(key);
          }
        });
      }

      return acc;
    }, new Set());
    filters = Array.from(filterSet);
  }

  return {
    filters,
    caseStudies,
    limit,
    offset,
    selectedFilters,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPosts,
    updatePreference,
  })(CaseStudiesPageTemplate),
);
