import React from 'react';
import { Link } from 'react-router-dom';

export default ({ banner }) => {
  const {
    image,
    content,
    changePasswordButton,
  } = banner;

  const imageUrl = image && image.url ? image.url : 'https://admin.freestylediabetes.co.uk/wp-content/uploads/2018/10/home-1.jpg';

  return (
    <div className="banner row background-image-cover" style={{backgroundImage: 'url(' + imageUrl + ')'}} >
      <div className="overlay" />
      <div className="left-center container">
        <div className="row">
          <div className="col-12">
            <div dangerouslySetInnerHTML={{__html: content}}></div>
            {changePasswordButton && (
              <Link to="/change-password" class="btn btn-orange" style={{marginTop: '1rem'}}>Change password</Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
