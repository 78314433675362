import { CALL_API, Schemas } from '../middleware/api';

/**
 * Fetch all cta blocks
 */

export const CTA_BLOCKS_REQUEST = 'CTA_BLOCKS_REQUEST';
export const CTA_BLOCKS_SUCCESS = 'CTA_BLOCKS_SUCCESS';
export const CTA_BLOCKS_FAILURE = 'CTA_BLOCKS_FAILURE';

const fetchCtaBlocks = () => ({
  [CALL_API]: {
    types: [CTA_BLOCKS_REQUEST, CTA_BLOCKS_SUCCESS, CTA_BLOCKS_FAILURE],
    endpoint: 'acf/v3/ctablocks',
    schema: Schemas.CTA_BLOCK_ARRAY,
  },
});

export const loadCtaBlocks = () => (dispatch, getState) => {
  const blocks = getState().entities.ctaBlocks;
  if (Object.keys(blocks).length > 0) {
    return null;
  }

  return dispatch(fetchCtaBlocks());
};
