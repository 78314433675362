import { CALL_API } from '../middleware/api';

export const ORDER_REQUEST = 'ORDER_REQUEST';
export const ORDER_SUCCESS = 'ORDER_SUCCESS';
export const ORDER_FAILURE = 'ORDER_FAILURE';

const fetchOrder = payload => ({
  [CALL_API]: {
    types: [ORDER_REQUEST, ORDER_SUCCESS, ORDER_FAILURE],
    endpoint: 'abbott/v1/order',
    method: 'POST',
    body: payload,
  },
});

export const loadOrder = payload => (dispatch, getState) => dispatch(fetchOrder(payload));
