import { CALL_API, Schemas } from '../middleware/api';

export const USERS_REQUEST = 'USERS_REQUEST';
export const USERS_SUCCESS = 'USERS_SUCCESS';
export const USERS_FAILURE = 'USERS_FAILURE';

const fetchUsers = () => ({
  [CALL_API]: {
    types: [USERS_REQUEST, USERS_SUCCESS, USERS_FAILURE],
    endpoint: 'wp/v2/users',
    schema: Schemas.USER_ARRAY,
  },
});

export const loadUsers = () => (dispatch, getState) => {
  const users = getState().entities.users;
  if (Object.keys(users).length > 0) {
    return null;
  }

  return dispatch(fetchUsers());
};

export const USER_REQUEST = 'USER_REQUEST';
export const USER_SUCCESS = 'USER_SUCCESS';
export const USER_FAILURE = 'USER_FAILURE';

const fetchUser = userId => ({
  userId,
  [CALL_API]: {
    types: [USER_REQUEST, USER_SUCCESS, USER_FAILURE],
    endpoint: `wp/v2/users/${userId}`,
    schema: userId === 'me' ? null : Schemas.USER,
    query: { context: 'edit' },
  },
});

export const loadUser = userId => (dispatch, getState) => {
  const user = getState().entities.users[userId];
  if (user) {
    return null;
  }

  return dispatch(fetchUser(userId));
};

export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE';

const fetchUpdateUser = body => ({
  [CALL_API]: {
    types: [UPDATE_USER_REQUEST, UPDATE_USER_SUCCESS, UPDATE_USER_FAILURE],
    endpoint: 'wp/v2/users/me',
    schema: null,
    method: 'POST',
    query: { context: 'edit' },
    body,
  },
});

export const updateUser = body => (dispatch, getState) => dispatch(fetchUpdateUser(body));

// List meta data keys
export const MARKETING_META = [
  // Wordpress register_meta not support array for meta value, hence flatten the array - not ideal!
  'marketingEducationContentEmail',
  'marketingEducationContentApp',
  'marketingFeedbackSurveysEmail',
  'marketingFeedbackSurveysApp',
  'marketingOffersAndPromotionsEmail',
  'marketingOffersAndPromotionsApp',
];

export const COMMON_META = MARKETING_META.concat([
  'title',
  'address',
  'country',
  'postcode',
  'county',
  'pushNotification',
]);

export const CONSUMER_META = COMMON_META.concat([
  'meterType',
  'meterSerialNumber',
  'adjustInsulinAtMealTime',
  'sourceOfHelp',
]);

export const HCP_META = COMMON_META.concat([
  'jobRole',
]);

/**
 * GDPR request
 */
export const GDPR_REQUEST_REQUEST = 'GDPR_REQUEST_REQUEST';
export const GDPR_REQUEST_SUCCESS = 'GDPR_REQUEST_SUCCESS';
export const GDPR_REQUEST_FAILURE = 'GDPR_REQUEST_FAILURE';

const gdprRequest = userId => ({
  [CALL_API]: {
    types: [GDPR_REQUEST_REQUEST, GDPR_REQUEST_SUCCESS, GDPR_REQUEST_FAILURE],
    endpoint: `abbott/v1/users/gdpr-request/${userId}`,
    method: 'POST',
  },
});

export const doGdprRequest = userId => dispatch => dispatch(gdprRequest(userId));
