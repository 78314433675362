import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

export default class HomePageTemplate extends Component {
  static propTypes = {
    page: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
  };

  render() {
    const { page, user } = this.props;
    const {
      acf: { education, modules, ordering, reviews, adBanner },
    } = page;

    const showReviewsAndOrdering = !user.meta || (user.meta && user.meta.type === 'hcp') ? false : true;

    // conditional logic to show the ad block IF the Ad Banner image is present (check to see if the Ad Banner has been published)
    const showAdBanner = adBanner && adBanner.image ? true : false;

    var settings = {
      dots: true,
      arrows: false,
      infinite: true,
      speed: 500,
      autoplay: true,
      autoplaySpeed: 6000,
      adaptiveHeight: false,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    return (
      <div>
        {showAdBanner && (
          <div className="adBanner row background-image-cover" style={{ backgroundImage: 'url(' + adBanner.image.url + ')' }}>
            <div className="left-center container">
              <div className="row">
                <div className="col-12">
                  <div dangerouslySetInnerHTML={{ __html: adBanner.content }}></div>
                  {adBanner.link && (
                    <Link
                      className="btn btn-white"
                      to={adBanner.link.url
                        .replace('http://progress.freestylediabetes.co.uk', '')
                        .replace('https://progress.freestylediabetes.co.uk', '')
                        .replace('http://admin.freestylediabetes.co.uk', '')
                        .replace('https://admin.freestylediabetes.co.uk', '')}
                    >
                      {adBanner.link.title}
                    </Link>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
        <div className="modules row">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 background-image-contain" style={{ backgroundImage: 'url(' + modules.image.url + ')' }}></div>
              <div className="col-12 col-md-6">
                <Slider ref={slider => (this.slider = slider)} {...settings}>
                  {modules.slider.map(function (slide, slideIndex) {
                    return <div key={slideIndex} dangerouslySetInnerHTML={{ __html: slide.content }}></div>;
                  })}
                </Slider>
              </div>
            </div>
          </div>
        </div>
        <div className="full-width-link row">
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-6 offset-md-6">
                {modules.link ? (
                  <Link
                    to={modules.link.url
                      .replace('http://progress.freestylediabetes.co.uk', '')
                      .replace('https://progress.freestylediabetes.co.uk', '')
                      .replace('http://admin.freestylediabetes.co.uk', '')
                      .replace('https://admin.freestylediabetes.co.uk', '')}
                  >
                    START YOUR MODULES
                  </Link>
                ) : (
                  <Link to="/sign-in">START YOUR MODULES</Link>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="education row background-image-cover" style={{ backgroundImage: 'url(' + education.image.url + ')' }}>
          <div className="left-center container">
            <div className="row">
              <div className="col-12">
                <div dangerouslySetInnerHTML={{ __html: education.content }}></div>
                {education.button && (
                  <Link
                    className="btn btn-white"
                    to={education.button.url
                      .replace('http://progress.freestylediabetes.co.uk', '')
                      .replace('https://progress.freestylediabetes.co.uk', '')
                      .replace('http://admin.freestylediabetes.co.uk', '')
                      .replace('https://admin.freestylediabetes.co.uk', '')}
                  >
                    {education.button.title}
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
        {showReviewsAndOrdering && (
          <div className="row">
            <div className="reviews col-12 col-md-6 background-image-contain" style={{ backgroundImage: 'url(' + reviews.image.url + ')' }}>
              <div dangerouslySetInnerHTML={{ __html: reviews.content }}></div>
            </div>
            <div
              className="ordering col-12 col-md-6 background-image-contain"
              style={{ backgroundImage: 'url(' + ordering.image.url + ')' }}
            >
              <div dangerouslySetInnerHTML={{ __html: ordering.content }}></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
