import { merge } from 'lodash/object';
import * as AuthenticationActionTypes from '../actions/authentication-actions';
import * as UsersActionTypes from '../actions/users-actions';


const initialState = {};

// Updates current user information in response to token and user requests
const user = (state = initialState, { type, response, userId }) => {
  switch (type) {
    case AuthenticationActionTypes.TOKEN_SUCCESS:
      return merge({}, state, response);

    case AuthenticationActionTypes.LOGOUT:
      return {};

    case UsersActionTypes.USER_SUCCESS:
      if (userId === 'me') {
        return merge({}, state, response);
      }
      break;

    case UsersActionTypes.UPDATE_USER_SUCCESS:
      return merge({}, state, response);

    default:
      return state;
  }
};

export default user;
