import {
  UPDATE_PREFERENCE,
} from '../actions/preferences-actions';

// Initial preferences state
const initialState = {
  caseStudiesSelections: {
    limit: 10,
    offset: 0,
    selectedFilters: [],
  },
};

// User preferences - only store in the redux for now
const preferences = (state = initialState, action) => {
  const {
    type,
    payload,
  } = action;

  // Do actions
  if (type === UPDATE_PREFERENCE) {
    return Object.assign(state, payload);
  }

  return state;
};

export default preferences;
