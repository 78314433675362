import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';

import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { loadPosts } from '../../actions/posts-actions';
import { loadProgress } from '../../actions/progress-actions';

import ModuleIntro from '../modules/ModuleIntro';
import Tutorial from '../modules/Tutorial';
import Disclaimer from '../Disclaimers';
import filter from 'lodash/filter';
import TagManager from 'react-gtm-module';
// import find from 'lodash/find'
// import reactStringReplace from 'react-string-replace';

function SampleNextArrow(props) {
  const { onClick, finishLink, sectionLength, i } = props;
  if (sectionLength === i) {
    return <Link to={finishLink} onClick={onClick} className="button button--text button--icon slick-next last-slide"></Link>;
  } else {
    return <button to={finishLink} type="button" onClick={onClick} className="button button--text button--icon slick-next"></button>;
  }
}

function SamplePrevArrow(props) {
  const { onClick, sectionLength, i } = props;
  if (i !== 0) {
    return (
      <button type="button" onClick={onClick} className="button button--text button--icon slick-prev">
        {sectionLength}
      </button>
    );
  } else {
    return '';
  }
}

class Module extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionArray: [],
      selected: {},
      complete: {},
      progress: 0,
      image: '',
      activeSlide: 0,
    };
    this.renderQuestions = this.renderQuestions.bind(this);
    this.selectAnswer = this.selectAnswer.bind(this);
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.contentClickHandler = this.contentClickHandler.bind(this);
  }

  componentDidMount() {
    this.props.loadPosts('modules');
    this.setState({ questionArray: this.props.post.acf.question });
    this.calculateProgress();
    this.setQuestionState();
    this.props.loadPosts('tab_content_block');

    console.log('component did mount');

    window.addEventListener('beforeinstallprompt', e => {
      // For older browsers
      e.preventDefault();
      console.log('Install Prompt fired');
      this.installPrompt = e;
    });
  }

  contentClickHandler(e) {
    setTimeout(() => {
      this.slider.forceUpdate();
    }, 100);
    this.slider.forceUpdate();
  }

  tabClickHandler(e) {
    if (window.Element && !Element.prototype.closest) {
      Element.prototype.closest = function (s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
          i,
          el = this;
        do {
          i = matches.length;
          while (--i >= 0 && matches.item(i) !== el) {}
        } while (i < 0 && (el = el.parentElement));
        return el;
      };
    }
    e.preventDefault();

    var el = e.target;
    var parent = el.closest('li');
    // var parent2 = el.closest('a')

    if (parent) {
      if (parent.classList.contains('tab-click-handler')) {
        var imageToShow = 'image-' + e.target.closest('a').id;
        var elements = document.getElementById(imageToShow).parentElement.querySelectorAll('.active');
        for (var i = 0; i < elements.length; i++) {
          elements[i].classList.remove('active');
        }
        document.getElementById(imageToShow).classList.toggle('active');
      }
    }
  }

  restartQuiz() {
    const post = this.props.post;
    const userMeta = this.props.user;
    var user = userMeta;

    var questionArray = user.question[post.id];
    Object.keys(questionArray)
      .filter(item => isNaN(item) && questionArray[item].correct)
      .forEach(function (key) {
        questionArray[key] = '';
      });

    var questionArray2 = user.question;
    questionArray2[post.id] = questionArray;
    this.props.loadProgress(user.id, questionArray2);
    this.setState({ questionArray: this.props.post.acf.question });
    this.calculateProgress();
    this.setQuestionState();

    this.setState({ selected: {} });
    this.setState({ complete: {} });
  }

  setQuestionState() {
    const post = this.props.post;
    const questionArray = this.props.user.question[post.id];
    var thisModule = this;
    var selected = this.state.selected;
    var complete = this.state.complete;
    Object.keys(questionArray)
      .filter(item => isNaN(item) && questionArray[item].correct)
      .forEach(function (key) {
        if (questionArray[key] !== '') {
          var parent = key.replace('question', '');
          var answer = questionArray[key].selectedAnswer;
          var correct = questionArray[key].correct;
          selected[parent + '' + answer] = correct;
          complete[parent] = 'complete';
          thisModule.setState({ selected: selected });
        }
      });
  }

  calculateProgress() {
    const post = this.props.post;
    const questionArray = this.props.user.question[post.id];
    var completedQuestionCount = 0;
    var numberOfQuestions = Object.keys(questionArray).filter(item => isNaN(item) && questionArray[item].correct).length;
    var userRole = this.props.user.roles[0];
    if (userRole === 'hcp') {
      userRole = userRole.toUpperCase();
    } else {
      userRole = userRole.charAt(0).toUpperCase() + userRole.slice(1);
    }

    Object.keys(questionArray)
      .filter(item => isNaN(item))
      .forEach(function (key) {
        if (questionArray[key] !== '' && questionArray[key].correct === 'correct') {
          completedQuestionCount++;
        }
      });

    var progressPercentage = (completedQuestionCount / numberOfQuestions) * 100;
    this.setState({ progress: progressPercentage });

    if (progressPercentage === 100) {
      // GTM 'Module complete' event
      TagManager.dataLayer({
        dataLayer: {
          event: 'custom_event',
          category: userRole + ' Module',
          action: 'Complete',
          label: this.props.post.title.rendered,
        },
      });
    }
  }

  selectAnswer(parentQuestion, selectedAnswer) {
    const post = this.props.post;
    const questions = post.acf.question;
    const userMeta = this.props.user;
    var user = userMeta;
    var answer = questions[parentQuestion].answers[selectedAnswer].isCorrectAnswer;
    var questionArray = user.question;
    var module = post.id;

    var selected;
    var complete;

    if (answer !== '') {
      questionArray[module]['question' + parentQuestion] = { correct: 'correct', selectedAnswer: selectedAnswer };
      this.props.loadProgress(user.id, questionArray);
      selected = this.state.selected;
      selected[parentQuestion + '' + selectedAnswer] = 'correct';
      this.setState({ selected: selected });
      complete = this.state.complete;
      complete[parentQuestion] = 'complete';
      this.setState({ complete: complete });
    } else {
      questionArray[module]['question' + parentQuestion] = { correct: 'incorrect', selectedAnswer: selectedAnswer };
      this.props.loadProgress(user.id, questionArray);
      selected = this.state.selected;
      selected[parentQuestion + '' + selectedAnswer] = 'incorrect';
      this.setState({ selected: selected });
      complete = this.state.complete;
      complete[parentQuestion] = 'complete';
      this.setState({ complete: complete });
    }
    this.calculateProgress();
  }

  tabbedArea(tabId = '') {
    const { tabs } = this.props;

    let tabData = '';
    let tabImage = '';
    if (tabId !== '') {
      for (let key in tabs) {
        if (tabs.hasOwnProperty(key)) {
          if (tabs[key].id === tabId) {
            tabData = tabs[key].acf.tabbedArea;
            tabImage = tabs[key].acf.tabbedAreaMainImage;
          }
        }
      }

      if (tabData) {
        var col = '';
        var display = '';
        var tabbedDivId = Math.floor(Math.random() * 100);
        if (tabImage) {
          col = 'col-8';
        } else {
          col = 'col-12';
          display = 'hide';
        }
        return (
          <div onClick={this.tabClickHandler}>
            <div className="row">
              <div className={'col-4 ' + display}>
                <div className="body-diagram">
                  <div className="diagram">
                    <img className="active" src={tabImage} alt="body" width="90%" />
                    {tabData.map(function (tab, tabIndex) {
                      const imageClass = 'image-body-tab' + tabbedDivId + tabIndex;
                      return <img key={tabIndex} id={imageClass} src={tab.mainImage} alt="" width="90%" />;
                    })}
                  </div>
                </div>
              </div>
              <div className={col}>
                <ul className="nav nav-tabs nav-justified nav-fill" id="myTab" role="tablist">
                  {tabData.map(function (tab, tabIndex) {
                    const id = 'body-tab' + tabbedDivId + tabIndex;
                    const href = '#body' + tabbedDivId + tabIndex;
                    const control = 'body' + tabbedDivId + tabIndex;
                    return (
                      <li key={tabIndex} className="nav-item tab-click-handler">
                        <a
                          className="nav-link"
                          id={id}
                          data-toggle="tab"
                          href={href}
                          role="tab"
                          aria-controls={control}
                          aria-selected="true"
                        >
                          {(() => {
                            if (tab.iconTitleOverride === '') {
                              return <img src={tab.icon} alt="-" />;
                            } else {
                              return <div>{tab.iconTitleOverride}</div>;
                            }
                          })()}
                        </a>
                      </li>
                    );
                  })}
                </ul>
                <div className="tab-content" id="myTabContent">
                  {tabData.map(function (tab, tabIndex) {
                    const id = 'body-tab' + tabbedDivId + tabIndex;
                    const href = 'body' + tabbedDivId + tabIndex;
                    return (
                      <div
                        key={tabIndex}
                        className="tab-pane fade"
                        id={href}
                        role="tabpanel"
                        aria-labelledby={id}
                        dangerouslySetInnerHTML={{ __html: tab.content }}
                      ></div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  next() {
    this.slider.slickNext();
  }
  previous() {
    this.slider.slickPrev();
  }

  renderQuestions(post, modules, user) {
    console.log(post);
    const page = this;
    const sections = post.acf.section;
    const thisQuestion = this;
    const finishLink = '/' + this.props.user.roles[0] + '/academy';
    const progressPercentage = (this.state.activeSlide / sections.length) * 100;
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <SamplePrevArrow sectionLength={sections.length} i={this.state.activeSlide} />,
      nextArrow: <SampleNextArrow finishLink={finishLink} sectionLength={sections.length} i={this.state.activeSlide} />,
      afterChange: current => this.setState({ activeSlide: current }),
      adaptiveHeight: true,
      beforeChange: (current, next) => {
        setTimeout(() => this.setState({ activeSlide: next }), 500);
        document.getElementById('module').scrollIntoView();
      },
    };
    return (
      <div id="module">
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow={progressPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progressPercentage + '%', backgroundColor: '#ffd000' }}
          ></div>
        </div>
        <Slider ref={slider => (this.slider = slider)} {...settings}>
          {sections.map(function (section, sectionIndex) {
            // reactStringReplace(section.sectionContent, <iframe>(.*?)</iframe>, (match, i) => (
            //   <div class="video-container">{match}</div>
            // ));
            const contentReplaced = section.sectionContent
              .replace(/<iframe/g, '<div class="video-container"><p align="middle"><iframe')
              .replace(/<\/iframe>/g, '</iframe></p></div>');
            // console.log(section, contentReplaced);

            return (
              <div key={sectionIndex}>
                <div
                  onClick={page.contentClickHandler}
                  className="section background-image-cover content-section"
                  style={{ background: '' + post.acf.moduleBackgroundColor + '' }}
                >
                  <div className="container">
                    <div className="col-md-12">
                      Section {sectionIndex + 1}/{sections.length}
                      <h2 dangerouslySetInnerHTML={{ __html: section.sectionTitle }}></h2>
                      <div dangerouslySetInnerHTML={{ __html: contentReplaced }}></div>
                      <div className="full-width-tabs">{page.tabbedArea(section.sectionTabbedArea)}</div>
                    </div>
                  </div>
                </div>
                <div onClick={page.contentClickHandler} /* style={{ background : ''+post.acf.moduleColor+''  }} */ className="example">
                  {section.exampleTitle && section.exampleContent && (
                    <div className="container">
                      <div className="example-header">
                        <div className="col-12">
                          <h2 dangerouslySetInnerHTML={{ __html: section.exampleTitle }}></h2>
                        </div>
                      </div>
                      <div className="example-content">
                        <div className="col-12">
                          <div dangerouslySetInnerHTML={{ __html: section.exampleContent }}></div>
                        </div>
                        <div className="full-width-tabs">{page.tabbedArea(section.exampleTabbedArea)}</div>
                      </div>
                    </div>
                  )}
                  <div style={{ textAlign: 'center' }}>
                    {(() => {
                      if (sectionIndex !== 0) {
                        return (
                          <button className="btn prev btn-orange" onClick={thisQuestion.previous}>
                            Previous
                          </button>
                        );
                      }
                    })()}
                    <button className="btn btn-orange" onClick={thisQuestion.next}>
                      Next
                    </button>
                  </div>
                </div>
              </div>
            );
          })}
          <div>
            <div>
              <div
                className="question background-image-cover"
                style={{
                  backgroundImage: 'url(' + this.props.post.acf.questionAreaHeaderImage + ')',
                  backgroundColor: '' + post.acf.moduleBackgroundColor + '',
                }}
              >
                <div className="container">
                  <div className="col-md-8">
                    <h3>QUESTIONS</h3>
                    <h2>LET'S REVIEW</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="example" style={{ background: '' + post.acf.moduleBackgroundColor + '' }}>
              <div className="container question-container">
                {this.state.questionArray.map((question, index) => {
                  return (
                    <div key={index}>
                      <div className="question-header">
                        <h2 dangerouslySetInnerHTML={{ __html: question.questionTitle }}></h2>
                      </div>
                      <div className="question-content">
                        <div key={index}>
                          <ul className={thisQuestion.state.complete[index]}>
                            {question.answers.map(function (answer, answerIndex) {
                              var className = thisQuestion.state.selected[index + '' + answerIndex];
                              if (thisQuestion.state.complete[index] !== 'complete') {
                                return (
                                  <li
                                    key={answerIndex}
                                    className={className}
                                    onClick={() => {
                                      thisQuestion.selectAnswer(index, answerIndex);
                                    }}
                                    dangerouslySetInnerHTML={{ __html: answer.answer }}
                                  ></li>
                                );
                              } else {
                                if (String(answer.isCorrectAnswer) === 'yes') {
                                  return (
                                    <li key={answerIndex} className="correct">
                                      <div dangerouslySetInnerHTML={{ __html: answer.answer }}></div>{' '}
                                      <div
                                        className="more-answer-details"
                                        dangerouslySetInnerHTML={{ __html: question.correctAnswerDetails }}
                                      ></div>
                                    </li>
                                  );
                                } else {
                                  return (
                                    <li key={answerIndex} className={className} dangerouslySetInnerHTML={{ __html: answer.answer }}></li>
                                  );
                                }
                              }
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>

              <button className="btn prev btn-orange" onClick={thisQuestion.previous}>
                Previous
              </button>
              <Link to={finishLink} className="btn finish btn-orange">
                Finish
              </Link>
              <button
                className="btn btn-orange"
                onClick={() => {
                  this.restartQuiz();
                }}
              >
                Restart Quiz
              </button>
            </div>
          </div>
        </Slider>

        {(post.acf.disclaimerList || post.acf.references) && (
          <div id="accordion" class="disclaimers">
            <div className="card">
              <div
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                className="card-header"
                id="headingOne"
              >
                <div className="col-12">
                  <h2 className="mb-0">References &amp; Disclaimers</h2>
                </div>
              </div>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                  <div className="col-12">
                    {post.acf.disclaimerList ? (
                      <ul>
                        {post.acf.disclaimerList &&
                          post.acf.disclaimerList.map((item, i) =>
                            item.reference && item.disclaimer ? (
                              <li key={i.toString()}>
                                {item.reference} <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : item.reference ? (
                              <li key={i.toString()}>{item.reference}</li>
                            ) : item.disclaimer ? (
                              <li key={i.toString()}>
                                <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : null,
                          )}
                      </ul>
                    ) : (
                      post.acf.references && <div dangerouslySetInnerHTML={{ __html: post.acf.references }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { post, user } = this.props;

    return (
      <div>
        <ModuleIntro
          postTitle={post.title.rendered}
          progress={this.state.progress}
          moduleIntro={post.acf.moduleIntro}
          moduleColour={post.acf.moduleColor}
          moduleIcon={post.acf.moduleIcon}
        />
        <div>
          <Tutorial user={user} />
        </div>

        <article>{this.renderQuestions(post)}</article>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts },
  } = state;

  // const type = user && user.meta && user.meta.type ? user.meta.type : ''

  // let currentRole = find(roles, (role) => {
  //   return role.slug === type
  // })

  const tabs = filter(posts, post => {
    if (post.type === 'tab_content_block') {
      return true;
    }
    return false;
  });

  return {
    tabs,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPosts,
    loadProgress,
  })(Module),
);
