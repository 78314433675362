import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link, Redirect, withRouter } from 'react-router-dom';

import { loadToken } from '../actions/authentication-actions';
import { loadUser } from '../actions/users-actions';

import Loader from '../components/Loader';
import BannerRow from '../components/pages/BannerRow';
import TagManager from 'react-gtm-module';

class LoginPage extends Component {
  static propTypes = {
    authentication: PropTypes.object.isRequired,
    loadToken: PropTypes.func.isRequired,
    loadUser: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      error: false,
      loading: false,
      success: false,
    };
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.renderForm = this.renderForm.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false, emailSent: false });
  }

  componentWillMount() {
    if (this.props.location && this.props.location.search === '?reset_success') {
      this.setState({ emailSent: true });
    }
  }

  componentWillReceiveProps(nextProps) {}

  onSubmit(event) {
    event.preventDefault();
    if (event.target.checkValidity() !== false) {
      if (this.refs.username.value && this.refs.password.value) {
        this.setState({
          error: false,
          loading: false,
          success: false,
        });
        this.props.loadToken(this.refs.username.value, this.refs.password.value).then(response => {
          if (response.error) {
            // hack to replace the error response
            this.setState({ error: response.error.replace('Lost your password?', 'Click here to reset your password.') });
          }
          this.props.loadUser('me');

          // GTM 'login' event
          TagManager.dataLayer({
            dataLayer: {
              event: 'custom_event',
              category: 'User',
              action: 'Login',
              label: 'User logged in successfully',
            },
          });
          console.log('GTM event: User logged in successfully');
        });
      }
    }
    event.target.classList.add('was-validated');
  }

  resetPasswordPrompt() {
    const { emailSent } = this.state;

    return (
      !emailSent && (
        <div className="col-12 text-center large-font">
          <b>Note:</b> If this is your first time logging in to the new system, please <Link to="/reset-password">reset your password</Link>
          .
          <br />
          <br />
        </div>
      )
    );
  }

  resetPasswordSuccess() {
    const { emailSent } = this.state;

    return (
      emailSent && (
        <div className="alert alert-success alert-dismissible fade show" style={{ marginBottom: '45px', fontSize: '16px' }} role="alert">
          <div>Your password has been reset. Please log in again.</div>
          <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
      )
    );
  }

  renderForm() {
    const {
      authentication: { isAuthenticating },
    } = this.props;

    return (
      <div className="inner-block bg-light">
        <form className="needs-validation bg-faded" onSubmit={this.onSubmit} noValidate>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="loginUsername">
              Username
            </label>
            <div className="col-sm-8">
              <input
                type="text"
                className="form-control"
                id="loginUsername"
                placeholder="Enter username"
                ref="username"
                autoComplete="username"
                required
              />
              <div className="invalid-feedback">Please enter a username.</div>
            </div>
          </div>
          <div className="form-group row">
            <label className="col-sm-4 col-form-label" htmlFor="loginPassword">
              Password
            </label>
            <div className="col-sm-8">
              <input
                type="password"
                className="form-control"
                id="loginPassword"
                placeholder="Password"
                ref="password"
                autoComplete="current-password"
                required
              />
              <div className="invalid-feedback">Please enter a password.</div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-8 offset-sm-4">
              <div className="custom-control custom-checkbox custom-control-inline">
                <input type="checkbox" className="custom-control-input" id="loginRememberMe" ref="rememberMe" />
                <label className="custom-control-label" htmlFor="loginRememberMe">
                  Remember Me
                </label>
              </div>
            </div>
          </div>
          <div className="form-group row">
            <div className="col-sm-8 offset-sm-4">
              <button type="submit" className="btn btn-orange">
                Submit
                {isAuthenticating && <Loader />}
              </button>
              <div>
                <Link to="/reset-password">Forgotten password?</Link>
              </div>
            </div>
          </div>
        </form>

        {this.state.error && (
          <div className="alert alert-danger alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{ __html: this.state.error }}></div>
            <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
        )}
      </div>
    );
  }
  render() {
    const { authentication } = this.props;

    const banner = {
      content:
        "<h1>LOGIN NOW</h1><p>Login now to access FreeStyle Progress. Don’t have an account? <a href='/sign-up'>Register here</a></p>",
    };

    const link = this.props.location.state && this.props.location.state.from ? this.props.location.state.from.pathname : '/';

    if (this.state.success || authentication.isAuthenticated) {
      return <Redirect to={link} />;
    }

    return (
      <div className="page login">
        <BannerRow banner={banner} />
        <div className="pt-3 pb-3 container">
          <div className="row justify-content-center">
            {this.resetPasswordSuccess()}
            {this.resetPasswordPrompt()}
            <div className="outer-block col-12 bg-dark">{this.renderForm()}</div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { authentication } = state;

  return {
    authentication,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadToken,
    loadUser,
  })(LoginPage),
);
