import React from 'react'
import {
  Tab,
  Tabs,
  TabList,
  TabPanel,
} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { containsTable } from '../../utils';

import PropTypes from 'prop-types'

const ReactTabs = ({ tabData }) => {
  // Do nothing if there isn't tab data
  if (!tabData) {
    return false;
   }

  return (
    <div className="full-width-tabs">
      <div className="pt-3 pb-3 container">
        <div className="row">
          <Tabs>
            <TabList>
              {tabData.map(function(tab, tabIndex) {
                let tabIndexContent = tab.iconTitleOverride;
                if (tab.icon) {
                  tabIndexContent = <img src={tab.icon} alt={tab.iconTitleOverride}/>;
                }

                // Default to first tab
                const classNames = ['nav-item'];
                if (tabIndex === 0) {
                  classNames.push('active');
                }

                return (
                  <Tab key={tabIndex}>
                    {tabIndexContent}
                  </Tab>
                )
              })}
            </TabList>

            {tabData.map(function(tab, tabIndex) {
              const id = "body-tab" + tabIndex;
              const href = "body"+tabIndex;

              // Default to first tab
              const classNames = ['tab-pane'];
              if (tabIndex === 0) {
                classNames.push('active');
              }

              // check if the tab content have table
              containsTable (tab.content, classNames);

              return (
                <TabPanel key={tabIndex}>
                  <div key={tabIndex} className={classNames.join(' ')} id={href} role="tabpanel" aria-labelledby={id} dangerouslySetInnerHTML={{__html: tab.content}}></div>
                </TabPanel>
              )
            })}
            </Tabs>
        </div>
      </div>
    </div>
  )
}

// Component prop types
ReactTabs.propTypes = {
  tabData: PropTypes.array.isRequired,
}

export default ReactTabs;
