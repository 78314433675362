import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'

import { loadOrder } from '../../actions/order-actions'
import { loadPosts } from '../../actions/posts-actions'
import { loadProductTypes } from '../../actions/taxonomies-actions'
import Loader from '../Loader';
import  ModalImage  from 'react-modal-image'
import { isHcpAllowEmail } from '../../utils';

import find from 'lodash/find'
import filter from 'lodash/filter'
import groupBy from 'lodash/groupBy'
import map from 'lodash/map'

class OrderingPageTemplate extends Component {
  constructor(props) {
    super(props);

    // Set the default states
    this.state = {
      items: [],
      error: false,
      loading: false,
      success: false,
    }

    // Bind this to all functions
    this.onClick = this.onClick.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.handleDismissAlert = this.handleDismissAlert.bind(this);
    this.onRemoveFromCart = this.onRemoveFromCart.bind(this);
  }

  handleDismissAlert() {
    this.setState({ error: false, success: false })
  }

  componentWillMount() {
    this.props.loadPosts('products')
    this.props.loadProductTypes()
  }

  onClick(event) {
    let code = event.currentTarget.dataset.code;
    let currentItem = find(this.state.items, (item) => {
      return item.code === code;
    });
    if (!currentItem) {
      let currentItems = this.state.items;
      currentItems.push({code: code, quantity: 1});
      this.setState({items: currentItems});
    }
  }

  onSubmit(event) {
    const { items } = this.state;

    // Set the loading states
    this.setState({
      error: false,
      loading: true,
      success: false,
    });

    // Acutally place the order
    this.props.loadOrder({
      items,
    })
      .then((response) => {
        if (response.error) {
          this.setState({
            error: response.error,
            loading: false,
            success: false
          });
        } else {
          this.setState({
            items: [],
            error: false,
            loading: false,
            success: true
          });
        }
      })
  }

  onRemoveFromCart(product) {
    // remove the product of items
    const { items } = this.state;
    const newItems = items.filter(item => item.code !== product.acf.code);

    // Set the new state
    this.setState({
      items: newItems,
    });
  }

  renderFeedback() {
    const {
      error,
      success,
    } = this.state;

    // No display anything if there is no error or not success
    if (!error & !success) {
      return null;
    }

    let className = ['alert', 'alert-dismissible', 'fade', 'show'];
    let content = null;
    if (error) {
      className.push('alert-danger');
      content = (<div dangerouslySetInnerHTML={{__html: error}}></div>);
    } if (success) {
      className.push('alert-success');
      content = (<p>Order placed successfully</p>);
    }

    return (
      <div className={className.join(' ')} role="alert">
        {content}
        <button type="button" className="close" onClick={this.handleDismissAlert} aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    );
  }

  getValidationCheckContent() {
    const {
      currentRole,
      user,
    } = this.props;

    if (!user) {
      return <Loader />
    }

    const messages = [];
    // Its invalid if there is no address
    if (user && user.meta) {
      if (user.meta.address === '') {
        messages.push('Address - so we know where we will send the order');
      }
    }

    if (currentRole && currentRole.slug === 'consumer') {
      // Its consumer - check if there is meter select or not
      if (user && user.meta) {
        if (user.meta.meterType === '') {
          messages.push('Meter - your meter type and serial number');
        }
      }
    } else if (currentRole.slug === 'hcp') {
      // Make sure the hcp have a allowed email address
      if (!isHcpAllowEmail(user.email)) {
        messages.push('Email - In order to verify you as a healthcare professional, please use either your NHS, HSE or institution email address');
      }
    }

    // Output message
    if (messages.length === 0) {
      return false;
    } else {
      return (
      <div className="pt-3 pb-3 container">
        <h2>Sorry, Something is missing</h2>

        <ul>
        {messages.map((m, index) => (
          <li key={index}>{m}</li>
        ))}
        </ul>

        <p>
          Please amend the above in <Link to="/my-account">My Account</Link>
        </p>
      </div>
      )
    }
  }

  render() {
    const { items } = this.state;
    const { page, products, productsByProductType, productTypes } = this.props;

    // Do the validation check only allow user with address or meter to order it
    const authenticationContent = this.getValidationCheckContent();
    if (authenticationContent) {
      return authenticationContent;
    }

    const allProductsUi = map(productsByProductType, (groupedProductsByType, tIndex) => {
      const groupedProductsByTypeUi = map(groupedProductsByType, (product, pIndex) => {
        let itemInBasket = find(this.state.items, (item) => {
          return item.code === product.acf.code;
        });
        return (
          <tr key={pIndex}>
            <td className="code">{product.acf.code}</td>
            {(() => {
            if (product.acf.image) {
              return (
                <td className="image"><ModalImage small={product.acf.image} large={product.acf.image} /></td>
              )
            } else {
              return (
                <td className="image">No image</td>
              )
            }
            })()}

            <td className="item">
              <div><strong>{product.title.rendered}</strong></div>
              <div>{product.acf.item}</div>
            </td>
            {!itemInBasket ? (
              <td className="add" onClick={this.onClick} data-code={product.acf.code}><img src={process.env.PUBLIC_URL + '/icons/icon-plus.svg'} alt="Add" height="30px" width="30px" /></td>
            ) : (
              <td></td>
            )}
          </tr>
        )
      })
      const type = productTypes[tIndex] || ""
      return (
        <div key={tIndex}>
          <h2>{type.name}</h2>
          <table className="table">
            <thead>
              <tr>
                <th className="heading">Code</th>
                <th className="heading">Image</th>
                <th className="heading">Item</th>
                <th className="heading">Add to cart</th>
              </tr>
            </thead>
            <tbody>
              {groupedProductsByTypeUi}
            </tbody>
          </table>
        </div>
      )
    })

    const currentOrder = map(this.state.items, (item, index) => {
      let product = find(products, (product) => {
        return product.acf.code === item.code
      });
      return (
        <div key={index} className="cart-item">
          <div><strong>{product.title.rendered}</strong></div>
          <div className="icon-wrapper">
            <div className="icon-block">
              <img
                className="icon" src={process.env.PUBLIC_URL + '/icons/icon-bin.svg'}
                onClick={() => this.onRemoveFromCart(product)}
                alt=""
                width="15px"
              />
            </div>
          </div>
        </div>
      )
    })

    return (
      <div className="pt-3 pb-3 container">
        <div className="row">
          <div className="col-12" dangerouslySetInnerHTML={{__html: page.content.rendered}}></div>
        </div>
        <div className="row">
          <div className="col-12 col-md-9">
            <div className="outer-box">
              {allProductsUi}
            </div>
          </div>
          <div className="col-12 col-md-3">
            <div className="cart outer-box">
              <h2>Your Cart</h2>

              <p className="heading">Item</p>

              <div>
                {currentOrder}
              </div>

              {items.length > 0 && (
                <button
                  onClick={this.onSubmit}
                  className="btn btn-orange"
                >
                  PLACE ORDER
                  {this.state.loading && <Loader />}
                </button>
              )}

              {this.renderFeedback()}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts, productTypes, roles },
    user
  } = state

  const type = user && user.meta && user.meta.type ? user.meta.type : ''

  let currentRole = find(roles, (role) => {
    return role.slug === type
  })

  const products = filter(posts, (post) => {
    if (post.type === 'products') {
      if (currentRole) {
        return post.roles.indexOf(currentRole.id) > -1
      }
    }
    return false;
  })

  const productsByProductType = groupBy(products, (product) => {
    if (product.productTypes) { return product.productTypes[0] }
  });

  return {
    user,
    currentRole,
    products,
    productsByProductType,
    productTypes
  }
}

export default withRouter(connect(mapStateToProps, {
  loadOrder,
  loadPosts,
  loadProductTypes
})(OrderingPageTemplate))
