import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function NextArrow(props) {
  const { onClick, finishLink, sectionLength, i } = props;
  if (sectionLength === i) {
    return <Link to={finishLink} onClick={onClick} className="button button--text button--icon slick-next last-slide"></Link>;
  } else {
    return <button to={finishLink} type="button" onClick={onClick} className="button button--text button--icon slick-next"></button>;
  }
}

function PrevArrow(props) {
  const { onClick, sectionLength, i } = props;
  if (i !== 1) {
    return (
      <button type="button" onClick={onClick} className="button button--text button--icon slick-prev">
        {sectionLength}
      </button>
    );
  } else {
    return '';
  }
}

// Slide to output whatever its in the slide
// with added progress bar and speciallised next and previous buttons
class Slides extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeSlide: 1,
    };

    this.renderSlides = this.renderSlides.bind(this);
  }

  renderSlides(slides) {
    const { finishLink } = this.props;
    const { activeSlide } = this.state;

    const totalSlides = slides.length;
    const progressPercentage = (activeSlide / totalSlides) * 100;

    // Slider settings
    const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
      prevArrow: <PrevArrow sectionLength={slides.length} i={activeSlide} />,
      nextArrow: <NextArrow finishLink={finishLink} sectionLength={slides.length} i={activeSlide} />,
      beforeChange: (current, next) => {
        setTimeout(() => this.setState({ activeSlide: next + 1 }), 500);
      },
      adaptiveHeight: true,
    };

    return (
      <div className="slides">
        <div className="progress">
          <div
            className="progress-bar progress-bar-striped progress-bar-animated"
            role="progressbar"
            aria-valuenow={progressPercentage}
            aria-valuemin="0"
            aria-valuemax="100"
            style={{ width: progressPercentage + '%' }}
          ></div>
        </div>

        <div className="slide-num-indicator">
          Section {activeSlide} / {totalSlides}
        </div>

        <div className="slide-content clearfix">
          <Slider ref={c => (this.slider = c)} {...settings}>
            {slides}
          </Slider>
        </div>

        <div className="slide-bottom-actions example clearfix">
          {activeSlide !== 1 && (
            <button className="btn prev btn-orange" onClick={() => this.slider.slickPrev()}>
              Previous
            </button>
          )}

          {activeSlide !== totalSlides && (
            <button className="btn next btn-orange" onClick={() => this.slider.slickNext()}>
              Next
            </button>
          )}

          {activeSlide === totalSlides && (
            <Link to={finishLink} className="btn next btn-orange">
              Finish
            </Link>
          )}
        </div>
      </div>
    );
  }

  render() {
    // Get the slides
    const { slides } = this.props;

    // Do nothing if there isn't tab data
    if (!slides) {
      return false;
    }

    return this.renderSlides(slides);
  }
}

// Component prop types
Slides.propTypes = {
  slides: PropTypes.array.isRequired,
  finishLink: PropTypes.string,
};

Slides.defaultProps = {
  finishLink: 'academy',
};

export default Slides;
