import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { updateUserMeta } from '../../actions/progress-actions'

class Tutorial extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showTutorial: ''
    };
    var tutorial = this.props.user.tutorial;
    if (tutorial) {
      this.setState({showTutorial: 'hide'});
    }
  }

  closeTutorial(user, field = 'tutorial', closed = 'true') {
    this.setState({showTutorial: 'hide'})
    this.props.updateUserMeta(user, field, closed);
  }

  render() {
    var tutorial = this.props.user.meta.tutorial;
    if (tutorial === '') {
      return (
        <div className={ "tutorial-pop-up " + this.state.showTutorial} onClick={() => { this.closeTutorial(this.props.user.id, 'tutorial', true) }}>
          <div className="close-tutorial">x</div>
          Use the arrows, or swipe left and right to navigate the module.
        </div>
      );
    } else {
      return '';
    }
  }
}
const mapStateToProps = (state, ownProps) => {
  return {}
}
export default withRouter(connect(mapStateToProps, {
  updateUserMeta
})(Tutorial))
