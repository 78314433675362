import React, { Component } from 'react';
import PropTypes from 'prop-types';
// Todo: need to take out the link, so it will not depend on router
import { Link } from 'react-router-dom'

function getCurrentItems(items, selectedFilters, offset, limit) {
  // Get items that have selected filters
  const newItems = items.filter(item => {
    return selectedFilters.every(selectedFilter => {
      return item.filterFields[selectedFilter] && item.filterFields[selectedFilter] === true;
    });
  });

  return {
    total: newItems.length,
    currentItems: newItems.slice(offset, offset + limit),
  };
}

function camelCaseToString(text) {
  return text
          .replace(/([A-Z])/g,  ' $1')
          .replace(/^./,  str => str.toUpperCase());
}

// Select per page items
const availablePerPage = [2, 5, 10, 20];

class Listing extends Component {
  constructor(props) {
    super(props);

    // Set the default states
    this.state = {
      limit: props.limit,
      offset: props.offset,
      selectedFilters: props.selectedFilters,
    }

    // Bind this to functions
    this.handleFilterChange = this.handleFilterChange.bind(this);
    this.handleLimitChange = this.handleLimitChange.bind(this);
    this.handlePrevious = this.handlePrevious.bind(this);
    this.handleNext = this.handleNext.bind(this);
  }

  handleLimitChange(event) {
    const { offset } = this.props;
    const updatedState = {
      limit: parseInt(event.target.value, 10),
      offset,
    };
    this.setState(updatedState);

    // Selection changed
    this.selectionChanged(updatedState);
  }

  handleFilterChange(event) {
    const target = event.target;
    const filterName = target.name;
    const checked = target.checked;
    const { selectedFilters } = this.state;

    let newFilters = selectedFilters;
    if (checked) {
      // If its checked, then add it to the filter
      newFilters.push(filterName);
    } else {
      // Remove it if its not checked
      newFilters = newFilters.filter(filter => filter !== filterName);
    }

    // Set the new filters
    const updatedState = {
      offset: 0,
      selectedFilters: newFilters,
    };
    this.setState(updatedState);

    // Selection changed
    this.selectionChanged(updatedState);
  }

  selectionChanged(updatedState) {
    const { onSelectionChange } = this.props;

    // Call the parent about the filter changed
    onSelectionChange(Object.assign(this.state, updatedState));
  }

  handlePrevious() {
    const { offset, limit } = this.state;
    const updatedState = {
      offset: offset - limit,
    };

    // Set new offset
    this.setState(updatedState);

    // Selection changed
    this.selectionChanged(updatedState);
  }

  handleNext() {
    const { offset, limit } = this.state;
    const updatedState = {
      offset: offset + limit,
    };

    // Set new offset
    this.setState(updatedState);

    // Selection changed
    this.selectionChanged(updatedState);
  }

  renderItems(items) {
    // Getting the custom render item from the caller
    const { renderItem } = this.props;

    // Do nothing if its no items
    if (items.length === 0) {
      return <p>Sorry not results</p>;
    }

    // Using caller's render item
    if (renderItem) {
      return items.map(renderItem);
    }

    // Default render item
    return (
      <div className="current-items">
        {items.map((item) => (
          <div className="item-row row" key={item.id}>
            <div className='col-12 col-md-3 thumbnail-wrap'>
             <img className='thumbnail' src={item.filterFields.thumbnail} alt={item.title} />
            </div>
            <div className='col-12 col-md-9'>
              <h3>
                <Link className='nav-link' to={item.link}>
                  {item.title}
                </Link>
              </h3>
            
              <div dangerouslySetInnerHTML={{__html: item.content}}></div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  renderPagination(total, offset, limit) {
    const disabledPrevious = offset === 0 || offset < limit;
    const disabledNext = offset + 1 + limit > total;

    // No need to show if both previous and next is disabled
    if (disabledPrevious && disabledNext) {
      return false;
    }
    // Else need to show previous and next
    return (
      <div className="pagination-content flex-space-between">
        <button
          className="btn btn-orange"
          onClick={this.handlePrevious}
          disabled={disabledPrevious}
        >
          Previous
        </button>

        <button
          className="btn btn-orange"
          onClick={this.handleNext}
          disabled={disabledNext}
        >
          Next
        </button>
      </div>
    )
  }

  render() {
    const {
      filters,
      items,
    } = this.props;

    const {
      limit,
      offset,
      selectedFilters,
    } = this.state;

    // Work out the current items and total
    const {
      total,
      currentItems,
    } = getCurrentItems(items, selectedFilters, offset, limit);
    return (
      <div className="listing-content">
        <div className="filters">
          <h3>Filter by</h3>

          <div className="filter-content boxed-radio clearfix">
          { filters.length && filters.map((filter, index) => (
            <div className="boxed-radio-row" key={index}>
              <input id={filter} type="checkbox" name={filter} onChange={this.handleFilterChange} checked={selectedFilters.indexOf(filter) !== -1}/>

              <label className="filter-row" htmlFor={filter}>
                {camelCaseToString(filter)}
              </label>
            </div>
          )) }
          </div>
        </div>

        <div className="stats-content flex-space-between">
          <div className="total-content">Total: {total}</div>

          <div className="limit-content">
            <label>Showing:
            <select onChange={this.handleLimitChange} defaultValue={limit}>
              {availablePerPage.map((number) => (
                <option vaue={number} key={number}>{number}</option>
              ))}
            </select>
            per page
            </label>
          </div>
        </div>

        {this.renderItems(currentItems)}

        {this.renderPagination(total, offset, limit)}
      </div>
    )
  }
}

// Props
Listing.propTypes = {
  items: PropTypes.array,
  limit: PropTypes.number,
  offset: PropTypes.number,
  selectedFilters: PropTypes.array,
  onSelectionChange: PropTypes.func,
  renderItem: PropTypes.func,
};
Listing.defaultProps = {
  items: [],
  limit: 10,
  offset: 0,
  selectedFilters: [],
  onSelectionChange: () => {},
};

export default Listing;
