import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { loadPosts } from '../../actions/posts-actions';
import MeetingBox from '../Box/Meeting';
import TermsConditions from '../TermsConditions';
import { updateUserMeta } from '../../actions/progress-actions';
import { findRoleIdForUser, findCountryIdForUser, filterPostsByCountryAndRole } from '../../utils';
import Disclaimer from '../Disclaimers';
// The default post type
const POST_TYPE = 'meetingsinabox';

class MeetingsInABoxTemplate extends Component {
  constructor(props) {
    super(props);

    this.renderPosts = this.renderPosts.bind(this);
    this.onCloseBox = this.onCloseBox.bind(this);
    this.onBoxClicked = this.onBoxClicked.bind(this);
    this.onAcceptTC = this.onAcceptTC.bind(this);

    this.state = {
      showTC: false, // Show t&c
      showBox: false,
      selectedBox: false,
    };
  }

  componentWillMount() {
    // Load the meetings in a box post type
    this.props.loadPosts(POST_TYPE);
  }

  onBoxClicked(box, event) {
    event.preventDefault();

    this.setState({
      showBox: true,
      selectedBox: box,
      showTC: !!box.acf.tcsEnabled,
    });
  }

  onCloseBox(box) {
    this.setState({
      showBox: false,
      selectedBox: false,
    });
  }

  renderPosts(posts) {
    if (posts.length < 1) {
      return <p className="text-center mb-4">Please check back in the future for useful training resources.</p>;
    }

    return posts.map((post, index) => {
      // Get the first image
      let image = null;
      if (post.acf && post.acf.slides.length > 0) {
        image = post.acf.slides[0].image;
      }

      return (
        <div key={index} className="item-row">
          <h3>
            <a className="nav-link" href="#box" onClick={e => this.onBoxClicked(post, e)}>
              {post.title.rendered}
            </a>
          </h3>
          <div className="row-content clearfix">
            {image && (
              <a className="nav-link" href="#box" onClick={e => this.onBoxClicked(post, e)}>
                <img src={image} alt={post.title.rendered} />
              </a>
            )}
            <div className="summary" dangerouslySetInnerHTML={{ __html: post.content.rendered }}></div>
          </div>
        </div>
      );
    });
  }

  onAcceptTC() {
    this.setState({ showTC: false });
  }

  render() {
    const { page, posts } = this.props;

    // Get the state
    const { showTC, showBox, selectedBox } = this.state;

    // Show terms and conditions ?
    if (showTC) {
      console.log(selectedBox);
      const checklist = (selectedBox.acf.tcsCheckboxes || []).map((item, i) => {
        return {
          name: `${i}`,
          value: item.text,
        };
      });

      return (
        <TermsConditions
          content={selectedBox.acf.tcs}
          checklist={checklist}
          smallPrint={selectedBox.acf.tcsSmallPrint}
          onAccept={this.onAcceptTC}
        />
      );
    }

    // Now check if need to show the box
    if (showBox) {
      return <MeetingBox data={selectedBox} onClose={this.onCloseBox} />;
    }

    // Show listing
    return (
      <div className="pt-3 pb-3 container meetings">
        <div className="row">
          <div className="col-12 col-md-12" dangerouslySetInnerHTML={{ __html: page.content.rendered }}></div>
        </div>

        <div className="row">
          <div className="col-12 listing-content">{this.renderPosts(posts)}</div>
        </div>

        {(page.acf.disclaimerList || page.acf.references) && (
          <div id="accordion" class="disclaimers">
            <div className="card">
              <div
                data-toggle="collapse"
                data-target="#collapseOne"
                aria-expanded="true"
                aria-controls="collapseOne"
                className="card-header"
                id="headingOne"
              >
                <div className="col-12">
                  <h2 className="mb-0">References &amp; Disclaimers</h2>
                </div>
              </div>
              <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                <div className="card-body">
                  <div className="col-12">
                    {page.acf.disclaimerList ? (
                      <ul>
                        {page.acf.disclaimerList &&
                          page.acf.disclaimerList.map((item, i) =>
                            item.reference && item.disclaimer ? (
                              <li key={i.toString()}>
                                {item.reference} <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : item.reference ? (
                              <li key={i.toString()}>{item.reference}</li>
                            ) : item.disclaimer ? (
                              <li key={i.toString()}>
                                <Disclaimer id={item.disclaimer} />
                              </li>
                            ) : null,
                          )}
                      </ul>
                    ) : (
                      page.acf.references && <div dangerouslySetInnerHTML={{ __html: page.acf.references }}></div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

// Map state to props for the component
const mapStateToProps = (state, ownProps) => {
  const {
    entities: { posts, roles },
    user,
  } = state;

  const countriesMap = {
    uk: 15,
    ireland: 16,
  };

  const userRoleId = findRoleIdForUser(user, roles);
  const userCountryId = findCountryIdForUser(user, countriesMap);

  // Only get the meetings in a
  // 1. box post type
  // 2. HCP
  // 3. Non draft
  let meetingPosts = [];
  if (posts) {
    meetingPosts = filterPostsByCountryAndRole(posts, userRoleId, userCountryId, POST_TYPE);
  }

  return {
    user,
    posts: meetingPosts,
  };
};

export default withRouter(
  connect(mapStateToProps, {
    loadPosts,
    updateUserMeta,
  })(MeetingsInABoxTemplate),
);
