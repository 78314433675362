import { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { loadDisclaimers } from '../../actions/disclaimer-actions';

class Disclaimer extends Component {
  static propTypes = {
    id: PropTypes.number,
    disclaimers: PropTypes.object,
  };

  render() {
    if (
      this.props.disclaimers[this.props.id] &&
      this.props.disclaimers[this.props.id].acf &&
      this.props.disclaimers[this.props.id].acf.content
    ) {
      return this.props.disclaimers[this.props.id].acf.content;
    }

    return '';
  }
}

const mapStateToProps = state => {
  const {
    entities: { disclaimers },
  } = state;

  return {
    disclaimers,
  };
};

export default connect(mapStateToProps, {
  loadDisclaimers,
})(Disclaimer);
